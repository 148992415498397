.milestone-popup-container {
    position: absolute;
    padding: 0rem 1rem 1rem 3rem;
    background-color: white;
    border-radius: 0.5rem;
    z-index: 100;
    font-size: 1.1rem;
    height: 60%;
    width: 36rem;
    animation: Popup 0.5s ease-in-out;
    overflow: scroll;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;  /* For Internet Explorer and Edge */
}

.milestone-popup-container::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}

.milestone-close {
    position: absolute;
    top: 1rem;
    right: 0rem;
    border-radius: 100%;
}

.milestone-close:hover {
    display: flex;
    cursor: pointer;
    background-color: #D9D9D9;
}

.milestone-popup-grid {
    display: flex;
    flex-direction: column;
    align-items: left;
}

.mp-cont {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  width: 36rem;
  background-color: white;
  padding: 1.5rem 0rem 1.5rem 0rem;
  z-index: 1000;
  transition: padding 0.3s ease-in-out;
}

.mp-cont.scrolled {
    padding: 1rem 0rem 1rem 0rem;
    transition: padding 0.3s ease-in-out;
}

.new-cont {
    background-color: red;
    height: 4rem;
    position: relative;
    background-color: white;
}

.mp-0 {
    font-size: 1.2em;
    font-weight: 600;
    color:#06AB78;
}

.mp-1 {
    color: #67707C;
    font-size: 0.8em;
}

.mp-2 {
    font-size: 1.2em;
    font-weight: 600;
    color:#06AB78;
}

.mp-sep {
    height: 1px;
    width: 96%;
    background-color: #D9D9D9;
    margin: 2.5rem 0rem 1.5rem 0rem;
}

.mp-sep2 {
    height: 1px;
    width: 96%;
    background-color: #D9D9D9;
    margin: 2.5rem 0rem 2.5rem 0rem;
}

.mp-flex {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 1.5rem;
}

.mp-icon {
    height: 3.5rem;
}

.mp-arrow {
    margin-left: 1rem;
    margin-right: 1rem;
}

.mp-cards-container {
    display: flex;
    justify-content: left;
    align-items: center;
}
.mp-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0.1rem 0.5rem 0.1rem;
    border-radius: 0.6rem;
    width: 4.2rem;
    background-color: #f3f3f3;
    animation: overview-opac 0.3s ease-in-out 1
}

.mp-card-loot {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    padding: 1rem 1rem 1rem 1rem;
    border-radius: 0.6rem;
    background-color: #f3f3f3;
    width: 14rem;
    animation: overview-opac 0.3s ease-in-out 1;
    position: relative;
}

.flip-card {
    background-color: transparent;
    width: 240px;
    height: 110px;
    margin-left: 0.6rem;
    border-radius: 0.6rem;
    cursor: pointer;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 0.6rem;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 0.6rem;
  }
  
  .flip-card-front {
    background-color: #f3f3f3;
    color: black;
  }
  
  .flip-card-back {
    background-color: #78C6A3;
    color: white;
    transform: rotateY(180deg);
  }

.mp-grid {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
}

.mp-more-info {
    display: flex;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    cursor: pointer;
}

.idc-21 {
    display: flex;
    font-size: 1.1rem;
    font-weight: 700;
    margin-bottom: 0.3rem;
}

.idc-11 {
    font-weight: 600;
    font-size: 0.9rem;
}

.idc-rarity1 {
    font-weight: 500;
    font-size: 0.9rem;
    display: flex;
}

.back-mp0 {
    font-weight: 600;
    font-size: 1.2rem;
    margin-top: 0.3rem;
}

.back-mp1 {
    font-size: 0.9rem;
    font-weight: 500;
}

.nmr {
    font-size: 2.2em;
    font-weight: 700;
    margin-left: 1rem;
}

.empty-cont {
    display: flex;
    justify-content: left;
    align-items: center;
    filter:opacity(0.4);
    margin-top: -1rem;
    padding-bottom: 2rem;
}

.mp-desc {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8em;
    height: 100%;
    padding: 0 0.4rem 0 0.4rem;
}
