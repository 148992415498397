.landing-page-bg {
    background-color: #F8F8F8;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}

.hero-section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80rem;
    margin-top: 8rem;
    position: relative;
    z-index: 999;
}

.hero-circle-0 {
    position: absolute;
    left: -8%;
    top: -30%;
    background-color: #F1F1F1;
    height: 16rem;
    width: 16rem;
    border-radius: 100%;
    z-index: 0;
}

.hero-circle-1 {
    position: absolute;
    left: 16%;
    top: -30%;
    background-color: #F1F1F1;
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
    z-index: 0;
}

.hero-circle-2 {
    position: absolute;
    right: 20%;
    top: -6%;
    background-color: #F1F1F1;
    height: 4rem;
    width: 4rem;
    border-radius: 100%;
    z-index: 0;
}

.hero-circle-3 {
    position: absolute;
    left: 30%;
    bottom: -10%;
    background-color: #F1F1F1;
    height: 9rem;
    width: 9rem;
    border-radius: 100%;
    z-index: 0;
}

.hero-grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    font-size: 1.1rem;
    width: fit-content;
    z-index: 99;
}

.hero-gridX {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    width: 50%;
    z-index: 99;
}

.landing-page-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 75%;
}

.hero-title {
    color: #06AB78;
    font-weight: 800;
    font-size: 2.4em;
    width: 36rem;
    z-index: 12;
}

.hero-title2 {
    color: #06AB78;
    font-weight: 800;
    font-size: 2em;
    z-index: 12;
}

.hero-sub {
    color: white;
    font-size: 0.8125em;
    z-index: 12;
    margin-top: -0.4rem;
}

.cycleText-abs {
    position: absolute;
}

.cycle-text {
    display: block;
    position: relative;
    white-space: nowrap;
}

.hero-flip-in-anim {
    animation: hero-flip-in 0.7s ease-in-out 1;
}

.hero-flip-out-anim {
    animation: hero-flip-out 0.7s ease-in-out 1;
}

.hero-flip {
    color: #78C6A3;
    font-weight: 700;
    position: relative;
    display: inline-block;
    overflow: hidden;
    height: 1.2em; /* adjust to match the text size */
    vertical-align: bottom;
}

@keyframes hero-flip-in {
    0% {
        transform: translateY(-4rem);
    } 100% {
        transform: translateY(0rem);
    }
}

@keyframes hero-flip-out {
    0% {
        transform: translateY(0rem);
    } 100% {
        transform: translateY(4rem);
    }
}

.hero-flip-line {
    position: absolute;
    width: 98%;
    height: 0.16rem;
    background-color: #78C6A3;
    right: 0;
}

.hero-desc {
    width: 28rem;
    margin-top: 1.4rem;
    color: #77797B;
    font-size: 0.9em;
    z-index: 12;
}

.hero-buttons-container {
    margin-top: 4rem;
    z-index: 100;
}

.create-acc-circle {
    height: 4rem;
    width: 4rem;
    border-radius: 100%;
    background-color: #78C6A3;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #06AB78;
    z-index: 70;
}

.hero-create-acc-button {
    background-color: #FCFCFC;
    border-radius: 0.8rem;
    width: 11rem;
    height: 1.2rem;
    padding: 0.85rem 1rem 0.85rem 2.1rem;
    margin-left: -1.4rem;
    border: 1px solid #78C6A3;
    position: absolute;
    left: 100%;
    z-index: 3;
    animation: herobtn 0.8s ease-in-out 1;
    cursor: pointer;
}

@keyframes herobtn {
    0% {
        width: 0rem;
    }
    75% {
        width: 12rem;
    }
    100% {
        width: 11rem;
    }
}

.hero-create-acc-text:hover {
    color:#028f7c;
    cursor: pointer;
}

.hero-create-acc-text {
    position: absolute;
    left: 130%;
    width: 11rem;
    z-index: 100;
    color: #06AB78;
    font-weight: 600;
    animation: herotext 0.8s ease-in-out 1;
    display: flex;
    align-items: center;
    font-size: 0.9rem;
}

@keyframes herotext {
   0% {
    opacity: 0;
    transform: scale(1);
   }
   40% {
    opacity: 0;
    transform: scale(1);
   }
   80% {
    opacity: 0.8;
    transform: scale(1.05);
   } 
   100% {
    opacity: 1;
    transform: scale(1);
   }
}

.heromap {
    animation: heromap 6s ease-in-out infinite;
    cursor: pointer;
}

@keyframes heromap {
    0% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(10deg);
    }
    15% {
        transform: rotate(-10deg);
    }
    20% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.hero-have-acc {
    display: flex;
    margin-top: 0.4rem;
    margin-left: 3.4rem;
    color: #77797B;
    font-weight: 500;
    font-size: 0.8125rem;
    padding: 0.8rem 1.8rem 0.8rem 1.8rem;
    width: fit-content;
    border-radius: 0.4rem;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    z-index: 100;
}

.hero-have-acc:hover {
    cursor: pointer;
    background-color: rgb(231, 231, 231);
    color: #454b54;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    z-index: 100;
}

.market-item-large-container {
    position:  relative !important;
    z-index: 99 !important;
}

.hero-item-container {
    position: relative;
    display: flex;
    justify-content: right;
    align-items: center;
    width: fit-content;
    z-index: 999;
}

.hero-item-bg0 {
    position: absolute;
    background-color: #D9D9D9;
    height: 8rem;
    width: 18rem;
    border: 1px solid #949494;
    border-radius: 0.4rem;
    bottom: 8%;
    right: 0%;
    transform: rotate(-8deg);
    z-index: 2;
}

.hero-item-bg2 {
    position: absolute;
    background-color: #b1b1b1;
    border: 1px solid #b1b1b1;
    bottom: 32%;
    right: 0%;
    height: 6rem;
    width: 16rem;
    border-radius: 0.4rem;
    transform: rotate(2deg);
    z-index: 0;
}

.showcase-item-throw {
    border: 1px solid #c5c5c5;
    border-radius: 0.8rem;
    padding: 0.7rem 1.4rem 0.7rem 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: rgb(245, 245, 245);
    transition: background-color 0.3s ease-in-out;
    filter:brightness(0.9) grayscale(0.7);
    width: fit-content;
    z-index: 99;
    animation: showcase-throw 0.7s ease-in-out 1;
}

@keyframes showcase-throw {
    0% {
        transform: rotate(0deg) scale(1);
    }
    15% {
        transform: rotate(-10deg) scale(1);
    }
    40% {
        transform: rotate(10deg) scale(0.9) translate(0rem, 0rem);
        opacity: 1;
    }
    100% {
        transform: rotate(40deg) scale(0.5) translate(10rem, -12rem);
        opacity: 0;
    }
}

.bgpop {
    animation: bgpop 0.5s ease-in-out 1;
}

@keyframes bgpop {
    0% {
        transform: scale(1);
        background-color: #D9D9D9;
    }
    70% {
        transform: scale(1.2);
        transform: rotate(8deg);
        background-color: white;
    }
    100% {
        transform: scale(1);
        transform: rotate(-8deg);
        background-color: #D9D9D9;
    }
}

.lootanditems {
    width: fit-content;
    display: flex;
    justify-content: right;
    align-items: center;
    margin-top: 3rem;
    color: #3F3F3F;
    font-size: 0.9rem;
}

.loot-seg2 {
    height: 1px;
    width: 6rem;
    background-color: #cecece;
    margin-right: 0.7rem;
    margin-left: 0.7rem; 
}

.loot-seg {
    height: 1px;
    width: 6rem;
    background-color: #cecece;
    margin-right: 0.7rem;
    margin-left: 0.7rem;
}

.lootanditems-cont {
    width: fit-content;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.storage-item-curr {
    padding: 0.65rem;
    width: 2.6rem;
    border: 1px solid #d3d5d8;
    border-radius: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F2F2F2;
    opacity: 1;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out, border 0.3s ease-in-out;
    animation: storage-opacity 0.3s ease-in-out 1;
    position: relative;
    z-index: 10;
}

.storage-item-curr:hover {
    cursor: pointer;
    background-color: rgb(194, 233, 212);
    border: 1px solid #06AB78;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out, border 0.3s ease-in-out;
}

.storage-item-showcase {
    padding: 0.65rem;
    width: 2.6rem;
    border: 1px solid #DFE1E5;
    border-radius: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    opacity: 1;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out, border 0.3s ease-in-out;
    animation: storage-opacity 0.3s ease-in-out 1;
    position: relative;
    z-index: 10;
}

.storage-item-showcase:hover {
    cursor: pointer;
    background-color: rgb(194, 233, 212);
    border: 1px solid #06AB78;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out, border 0.3s ease-in-out;
}

.hero-arrow {
    position: absolute;
    right: -5%;
    top: -38%;
    z-index: 100;
    display: flex;
    font-size: 0.8125rem;
    font-weight: 600;
}

.s-arrow {
    transform: rotate(30deg) scale(1.2);
    margin-top: 1.2rem;
}

.s-text {
    animation: s-text 2s ease-in-out infinite;
    transform: translateZ(0);
    backface-visibility: hidden;
}

@keyframes s-text {
   0% {
    transform: rotate(0deg) translateZ(0);
   } 
   20% {
    transform: rotate(3deg) translateZ(0);
   }
   85% {
    transform: rotate(-3deg) translateZ(0);
   }
   100% {
    transform: rotate(0deg) translateZ(0);
   }
}

.landing-sec2 {
    width: 140%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 16rem;
    position: relative;
    height: max-content;
    background-color: #78C6A3;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.landing-sec2-bg {
    background-color: #78C6A3;
    position: absolute;
    width: 200%;
    height: 40rem;
    z-index: 0;
    top:-10%;
}

.lsb-0 {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #78C6A3;
    border-radius: 100%;
    height: 12rem;
    width: 12rem;
    top: -5rem;
    left: 6rem;
}

.lsb-0-circle {
    background-color: white;
    height: 10rem;
    width: 10rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lsb-0-img {
    height: 6rem;
    width: 6rem;
    transform: rotate(-15deg);
}

.lsb-1-img {
    height: 3rem;
    width: 3rem;
    transform: rotate(15deg);
}

.lsb-1 {
    position: absolute;
    background-color: #78C6A3;
    height: 6rem;
    width: 6rem;
    border-radius: 100%;
    top: -1rem;
    left: 16rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lsb-1-circle {
    background-color: white;
    height: 4.7rem;
    width: 4.7rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lsb-2 {
    position: absolute;
    background-color: #78C6A3;
    height: 12rem;
    width: 12rem;
    border-radius: 100%;
    top: -5rem;
    right: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lsb-3 {
    position: absolute;
    background-color: #78C6A3;
    height: 6rem;
    width: 6rem;
    border-radius: 100%;
    top: -1rem;
    right: 16rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.landing-sec2-container {
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12rem;
    z-index: 10;
}

.step-box {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    width: 25rem;
    height: 22rem;
    margin: 0 1rem 0 0rem;
}

.step-text {
    color: #06AB78;
    font-weight: 700;
    font-size: 1.3rem;
    margin-bottom: 1rem;
    margin-left: 0.625rem;
    color: white;
}

.step-cont {
    background-color: #FCFCFC;
    padding: 1rem;
    border: 1px solid #454b54;
    border-radius: 0.8em;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 0px 6px rgb(114, 126, 120);
}

.step-cont-top {
    display: flex;
    justify-content: left;
    width: 100%;
}

.step-cont-top-grid {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-left: 1rem;
    font-size: 0.9rem;
    margin-top: 0.4rem;
}

.scg-0 {
    color: #06AB78;
    font-size: 1.625em;
    font-weight: 600;
}

.scg-1 {
    color: #77797B;
    font-size: 0.8125em;
    font-weight: 500;
}

.create-entry-xd {
    width: max-content;
    width: 120%;
    border-radius: 0.8rem;
    margin-top: 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1.6rem;
}

.landing-button2 {
    width: 60%;
    background-color: #92DDBC;
    border: 1px solid #454B54;
    color: #454b54;
    border-radius: 0.4rem;
    font-weight: 600;
    padding: 0.8rem 0 0.8rem 0;
    margin-top: 1rem;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
    font-family: "Inter";
}

.landing-button2:hover {
    cursor: pointer;
    background-color: #81d3ba;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.landing-button2:active {
    transform: scale(0.95);
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.landing-button {
    width: 96%;
    background-color: #92DDBC;
    border: 1px solid #454B54;
    color: #454b54;
    border-radius: 0.4rem;
    font-weight: 600;
    padding: 0.8rem 0 0.8rem 0;
    margin-top: 1rem;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
    font-family: "Inter";
}

.landing-button:hover {
    cursor: pointer;
    background-color: #81d3ba;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.landing-button:active {
    transform: scale(0.95);
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.landing-button-sent {
    width: 96%;
    background-color: #92DDBC;
    border: 1px solid #454B54;
    color: #454b54;
    border-radius: 0.4rem;
    font-weight: 600;
    padding: 0.8rem 0 0.8rem 0;
    margin-top: 1rem;
    transition: background-color 0.3s ease-in-out;
    filter: grayscale(0.6);
    cursor: not-allowed;
    animation: sent 0.5s ease-in-out 1;
    font-family: "Inter";
}

@keyframes sent {
    0% {
        transform: scale(1);
    }
    80% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.step-circle {
    height: 4.4rem;
    width: 4.4rem;
    border-radius: 100%;
    background-color: #92DDBC;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.landing-streak-cont {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 2.4rem;
    border-radius: 0.8rem;
    border: 1px solid rgb(190, 190, 190);
    padding: 1.2rem 0rem 1.2rem 0rem;
}

.landing-streak-circle {
    height: 6.4rem;
    width: 6.4rem;
    background-color: #A6D8C2;
    border-radius: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.landing-ligher {
    position: absolute;
    height: 5.8rem;
    left: -5%;
    bottom: 12%;
}

.landing-streak-info-grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    margin-left: 1rem;
    width: 60%;
    font-size: 1rem;
}

.ff15 {
    color: #06AB78;
    font-weight: 600;
    font-size: 1.1em;
}

.ff16 {
    color: #77797B;
    font-weight: 500;
    font-size: 0.7em;
    margin-top: 0.2rem;
}

.hero-title-underline {
    height: 2px;
    width: 100%;
    background-color: white;
    margin-bottom: 1rem;
    animation: hero-underline 0.7s ease-in-out 1;
}

@keyframes hero-underline {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}

.step-img {
    animation: step-img 1s ease-in-out 1;
}

@keyframes step-img {
    0% {
        transform: scale(0.7);
    }
    80% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.landing-sec3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 12rem;
}

.explore-container {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 14rem;
    width: 66rem;
    position: relative !important;
    z-index: 20 !important;
}

.explore-container2 .explore-grid,
.landing-quest-container,
.explore-container {
  position: relative;
  z-index: 20; /* Elements above .explore-circle */
}

.explore-container2 {
    display: flex;
    justify-content: right;
    align-items: center;
    margin-top: 14rem;
    width: 66rem;
}

.warehouse-item2-hidden {
    height: 5.2rem;
    width: 5.2rem;
    background-color: #f0f0f0;
    border: 1px dashed rgb(212, 212, 212);
    border-radius: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease-in-out;
    z-index: 20;
}

.warehouse-empty {
    height: 5.2rem;
    width: 5.2rem;
    background-color: #FCFCFC;
    border: 1px dashed rgb(212, 212, 212);
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease-in-out;
    z-index: 10;
    position: relative;
    justify-content: center;
}

.warehouse-empty:hover {
    cursor: pointer;
    background-color: rgb(245, 245, 245);
    border: 1px solid rgb(184, 184, 184);
    transition: background-color 0.3s ease-in-out;
    z-index: 90;
}

.warehouse-item2 {
    height: 5.2rem;
    width: 5.2rem;
    background-color: #FCFCFC;
    border: 1px dashed rgb(212, 212, 212);
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease-in-out;
    z-index: 20;
    position: relative;
    justify-content: center;
}

.warehouse-item2:hover {
    cursor: pointer;
    background-color: rgb(194, 233, 212);
    border: 1px solid #06AB78;
    transition: background-color 0.3s ease-in-out;
    z-index: 90;
}

.landing-warehouse-cont {
    display: grid;
    grid-template-columns: 5.33rem 5.33rem 5.33rem 5.33rem 5.33rem;
    border: 1px solid #8c8e92;
    border-radius: 0.4rem;
    box-shadow: 0px 0px 10px rgb(201, 201, 201);
    position: relative;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
}

.explore-circle {
    position: absolute;
    height: 32rem;
    width: 32rem;
    right: -8%;
    border-radius: 100%;
    background-color: #F0F0F0;
    z-index: 0;
}

.explore-circle2 {
    position: absolute;
    height: 32rem;
    width: 32rem;
    right: -6%;
    border-radius: 100%;
    background-color: #F0F0F0;
    z-index: 0;
}

.explore-grid {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    font-size: 1rem;
    font-weight: 500;
    position: relative;
    z-index: 20;
    margin-left: 7rem;
}

.explore-grid2 {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    font-size: 1rem;
    font-weight: 500;
    position: relative;
    z-index: 20;
    margin-right: 3rem;
}

.explore-0 {
    color: #06AB78;
    font-size: 0.9em;
    z-index: 20;
}

.explore-1 {
    color: #06AB78;
    font-weight: 700;
    font-size: 1.8em;
    margin-top: 0.4rem;
    z-index: 20;
}

.explore-2 {
    color: #77797B;
    font-size: 0.9em;
    font-weight: 400;
    width: 25rem;
    margin-top: 1rem;
    z-index: 20;
}

.live-count {
    height: 0.8rem;
    width: 0.8rem;
    border-radius: 100%;
    background-color: #92DDBC;
    margin-right: 0.8rem;
    z-index: 20;
}

.live-cleared {
    font-size: 0.9em;
    z-index: 20;
}

.avail-active {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #78C6A3;
    animation: availActive 1s ease-in-out 1;
    left: 0;
}

@keyframes availActive {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}

.landing-quest-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 26rem;
    border: 1px solid #8c8e92;
    border-radius: 0.4rem;
    padding: 1rem;
    background-color: #FCFCFC;
    box-shadow: 0px 0px 10px rgb(201, 201, 201);
    position: relative;
}

.landing-quest-header0 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 50%;
    font-weight: 600;
    font-size: 1.1rem;
    color: #06AB78;
}

.landing-quest-header1 {
    display: flex;
    justify-content: right;
    align-items: center;
    width: 50%;
    position: relative;
}

.lqh-0 {
    color: #9D9D9D;
    font-size: 0.625rem;
    font-weight: 400;
}

.landing-time-remaining-abs {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    padding: 0.3rem 0.45rem 0.3rem 0.45rem;
    height: fit-content;
    color: #454B54;
    font-size: 0.8125rem;
    font-weight: 500;
    border-radius: 0.8rem;
    background-color: #ebebeb;
    border: 1px solid #d5d5d5;
}

.quest-item-container2 {
    width: 92%;
    height: fit-content;
    padding: 4%;
    border-radius: 0.8rem;
    border: 1px solid #DFE1E5;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 1rem;
    transition: background-color 0.3s ease-in-out;
}

.quest-item-container2:hover {
    background-color: #fafafa;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.landing-sticker-container {
    background-color: #FCFCFC;
    border: 1px solid #8c8e92;
    border-radius: 0.4rem;
    padding: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0px 0px 10px rgb(201, 201, 201);
    width: 26rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    position: relative;
    z-index: 20;
}

.landing-sticker-item-container {
    width: 100%;
    display: grid;
    grid-template-columns: 18% 18% 18% 18% 18%;
    column-gap: 0.4rem;
    row-gap: 0.6rem;
    margin-top: 1rem;
    z-index: 20;
}

.landing-sticker-item-null {
    padding: 0.4rem;
    border: 1px solid #b6b8bb;
    border-radius: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease-in-out;
    transition: background-color 0.3s ease-in-out;
    background-color: #e6e6e6;
    cursor: not-allowed;
}

.landing-sticker-item {
    padding: 0.4rem;
    border: 1px solid #b6b8bb;
    border-radius: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease-in-out;
}

.landing-sticker-item:hover {
    cursor: pointer;
    background-color: rgb(241, 241, 241);
    transition: background-color 0.3s ease-in-out;
}

.landing-sticker-active {
    padding: 0.4rem;
    background-color: rgb(194, 233, 212);
    border: 1px solid #06AB78;
    border-radius: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out
}

.landing-sticker-active:hover {
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out;
}

.landing-sec4 {
    margin-top: 6rem;
}

.questions-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40rem;
}

.questions-seg {
    height: 1px;
    width: 100%;
    background-color: rgb(180, 180, 180);
}

.questions-question {
    color: #454B54;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    margin-top: 1.4rem;
    margin-bottom: 1.4rem;
    position: relative;
}

.question-dropdown {
    position: absolute;
    right: 103%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.2rem;
    border-radius: 0.4rem;
    transition: background-color 0.3s ease-in-out;
}

.question-dropdown:hover {
    cursor: pointer;
    background-color: rgb(231, 231, 231);
    transition: background-color 0.3s ease-in-out;
}

.answer {
    display: flex;
    justify-content: left;
    width: 100%;
    height: 4rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    animation: show-answer 0.7s ease-in-out 1;
    overflow: hidden;
}

@keyframes show-answer {
    0% {
        height: 0rem;
    }
    100% {
        height: 4rem;
    }
}

.landing-contact-container {
    border: 1px solid #b6b8bb;
    padding: 1rem 3rem 2rem 3rem;
    padding-top: 2rem;
    border-radius: 0.8rem;
    background-color: #FCFCFC;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25rem;
    font-size: 1rem;
}

.contact-circle {
    height: 5rem;
    width: 5rem;
    border-radius: 100%;
    background-color: #A6D8C2;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.contact-0 {
    color: #454B54;
    font-size: 1.4em;
    font-weight: 600;
    margin-top: 2rem;
}

.contact-1 {
    color: #989898;
    font-size: 0.9em;
    font-weight: 400;
    margin-top: 0.4rem;
}

.footer-grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 0.9rem;
}

.fg0 {
    font-weight: 600;
    margin-top: 1rem;
}

.fg1 {
    font-size: 0.8125em;
    margin-top: 0.4rem;
    padding-bottom: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-icon {
    padding: 0.2rem;
    border-radius: 0.4rem;
    transition: background-color 0.3s ease-in-out;
    margin-right: 0.2rem;
}

.footer-icon:hover {
    cursor: pointer;
    background-color: rgb(212, 212, 212);
    transition: background-color 0.3s ease-in-out;
}

.warehouse-info-arrow {
    position: absolute;
    display: flex;
    z-index: 100;
    bottom: 106%;
    left: 45%;
    font-size: 0.8125rem;
    width: 18rem;
}

.warehouse-arrow-flip {
    transform: scaleX(-1);
    margin-right: 0.5rem;
}

.warehouse-info-sub {
    position: absolute;
    top: 105%;
    left: 1%;
    font-size: 0.8125rem;
    color: #06AB78;
}

.warehouse-item2-loot {
    height: 5.2rem;
    width: 5.2rem;
    border-radius: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease-in-out;
    z-index: 20;
    background-color: #78C6A3;
    border: 1px solid #068f63;
    position: relative;
}

.warehouse-item2-loot:hover {
    cursor: pointer;
    background-color: #52a490;
    transition: background-color 0.3s ease-in-out;
    z-index: 101;
}

.warehouse-img-loot {
    animation: img-loot-dance 1s ease-in-out infinite;
}

@keyframes img-loot-dance {
    0% {
        transform: rotate(0deg);
    }
    30% {
        transform: rotate(10deg);
    }
    70% {
        transform: rotate(-10deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.landing-unbox {
    animation: landing-unbox 2s ease-in-out 1;
}

@keyframes landing-unbox {
    0% {
        transform: translateY(0rem) rotate(0deg);
    }
    10% {
        transform:  rotate(-10deg);
    }
    20% {
        transform:  rotate(10deg);
    }
    30% {
        transform:  rotate(-10deg)
    }
    45% {
        transform: translateY(-1rem) rotate(0deg);
    }
    50% {
        transform: translateY(-1rem) rotate(0deg);
    }
    100% { 
        transform: translateY(0rem) rotate(0deg);
    }
    
}

.landing-quest-arrow {
    position: absolute;
    bottom: 105%;
    right: 0%;
    font-size: 0.8125rem;
    width: 18rem;
    display: flex;
}

.warehouse-debris-avail {
    animation: avail-debris 2s ease-in-out infinite;
}

@keyframes avail-debris {
    0% {
        transform: scale(0.95);
    }
    60% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(0.95);
    }
}

.start-your-quest {
    font-size: 0.9rem;
    background-color: #FCFCFC;
    color: #06AB78;
    border: 1px solid #06AB78;
    border-radius: 1.4rem;
    height: 3rem;
    padding: 0 2rem 0 3rem;
    margin-left: -2rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.start-your-quest:hover {
    cursor: pointer;
    background-color: #98d1b7;
    color: white;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.landing-nav {
    background-color: #FCFCFC;
    padding: 0.6rem 2rem 0.6rem 2rem;
    border: 1px solid #454B54;
    border-radius: 0.8rem;
}

#showcase-item {
    z-index: 999 !important;
    position: relative !important;
}

/* Media Queries */

@media only screen and (min-width: 360px) and (max-width: 449px) {
    .hero-grid {
        font-size: 0.625rem;
        align-items: center !important;
    }
    .create-acc-circle {
        height: 3rem;
        width: 3rem;
    }
    .start-your-quest {
        height: 2.4rem;
        font-size: 0.8125rem;
    }
    .hero-have-acc {
        margin-left: 2.4rem;
    }
    .hero-title {
        width: 20rem;
    }
    .loot-seg {
        width: 2rem;
    }
    .lai-text {
        width: 6rem;
    }
    .hero-desc {
        width: 20rem;
        font-size: 1.1em;
    }
    .hero-section {
        width: 100%;
        flex-direction: column;
    }
    #itemstuff {
        transform: scale(0.75);
        margin-top: 4rem;
    }
    .hero-item-container {
        justify-content: center;
    }
    .lootanditems {
        justify-content: center;
    }
    .lootanditems-cont {
        justify-content: center;
    }
    .landing-sec2-container {
        margin-top: 0rem;
        flex-direction: column;
        height: fit-content;
    }
    .step-box {
        transform: scale(0.7);
    }
    .landing-sec2-bg {
        height: 100rem;
    }
    .land-arr {
        transform: rotate(90deg);
    }
    .lsb-0 {
        height: 9rem;
        width: 9rem;
        opacity: 0;
    }
    .lsb-0-circle {
        height: 6.5rem;
        width: 6.5rem;
    }
    .lsb-0-img {
        height: 4.25rem;
        width: 4.25rem;
    }
    .lsb-1 {
        height: 4.8rem !important;
        width: 4.8rem !important;
        left: 13rem !important;
        opacity: 0;
    }
    .lsb-1-circle {
        height: 3.7rem !important;
        width: 3.7rem !important;
    }
    .lsb-1-img {
        height: 2rem !important;
        width: 2rem !important; 
    }
    .lsb-2 {
        height: 10rem;
        width: 10rem;
    }
    .lsb-2-circle {
        height: 8rem;
        width: 8rem;
    }
    .lsb-2-img {
        height: 5rem;
        width: 5rem;
    }
    .lsb-3 {
        height: 5rem !important;
        width: 5rem !important;
        right: 14rem !important;
    }
    .lsb-3-circle {
        height: 4.2rem !important;
        width: 4.2rem !important;
    }
    .lsb-3-img {
        height: 2.5rem !important;
        width: 2.5rem !important; 
    }
    .landing-sec2 {
        padding-top: 7rem;
    }
    .hero-title2 {
        font-size: 1.5em;
        width: 18rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .hero-sub {
        font-size: 0.75em;
        width: 21rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .hero-title-underline {
        width: 0;
    }
    .explore-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transform: scale(0.75);
        margin-top: 0rem;
    }
    .explore-container2 {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transform: scale(0.75);
        margin-top: 0rem;
    }
    .explore-grid {
        margin-top: 12rem;
        margin-left: 0rem;
    }
    .explore-grid2 {
        margin-bottom: 12rem;
        margin-right: 0rem;
    }
    .questions-container {
        width: 17rem;
    }
    .questions-question {
        font-size: 0.9rem;
    }
    .answer {
        font-size: 0.9rem;
    }
    .landing-contact-container {
        font-size: 0.8125rem;
        padding: 0.8rem 0rem 1.6rem 0rem;
        width: 22rem;
    }
    .contact-1 {
        width: 20rem;
    }
}

@media only screen and (min-width: 450px) and (max-width: 649px) {
    .hero-grid {
        font-size: 0.625rem;
        align-items: center !important;
    }
    .create-acc-circle {
        height: 3rem;
        width: 3rem;
    }
    .start-your-quest {
        height: 2.4rem;
        font-size: 0.8125rem;
    }
    .hero-have-acc {
        margin-left: 2.4rem;
    }
    .hero-title {
        width: 20rem;
    }
    .loot-seg {
        width: 2rem;
    }
    .lai-text {
        width: 6rem;
    }
    .hero-desc {
        width: 20rem;
        font-size: 1.1em;
    }
    .hero-section {
        width: 100%;
        flex-direction: column;
    }
    #itemstuff {
        transform: scale(0.75);
        margin-top: 4rem;
    }
    .hero-item-container {
        justify-content: center;
    }
    .lootanditems {
        justify-content: center;
    }
    .lootanditems-cont {
        justify-content: center;
    }
    .landing-sec2-container {
        margin-top: 0rem;
        flex-direction: column;
        height: fit-content;
    }
    .step-box {
        transform: scale(0.8);
    }
    .landing-sec2-bg {
        height: 100rem;
    }
    .land-arr {
        transform: rotate(90deg);
    }
    .lsb-0 {
        height: 9rem;
        width: 9rem;
        opacity: 0;
    }
    .lsb-0-circle {
        height: 6.5rem;
        width: 6.5rem;
    }
    .lsb-0-img {
        height: 4.25rem;
        width: 4.25rem;
    }
    .lsb-1 {
        height: 4.8rem !important;
        width: 4.8rem !important;
        left: 13rem !important;
        opacity: 0;
    }
    .lsb-1-circle {
        height: 3.7rem !important;
        width: 3.7rem !important;
    }
    .lsb-1-img {
        height: 2rem !important;
        width: 2rem !important; 
    }
    .lsb-2 {
        height: 10rem;
        width: 10rem;
    }
    .lsb-2-circle {
        height: 8rem;
        width: 8rem;
    }
    .lsb-2-img {
        height: 5rem;
        width: 5rem;
    }
    .lsb-3 {
        height: 5rem !important;
        width: 5rem !important;
        right: 14rem !important;
    }
    .lsb-3-circle {
        height: 4.2rem !important;
        width: 4.2rem !important;
    }
    .lsb-3-img {
        height: 2.5rem !important;
        width: 2.5rem !important; 
    }
    .landing-sec2 {
        padding-top: 7rem;
    }
    .hero-title2 {
        font-size: 1.5em;
        width: 22rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .hero-sub {
        font-size: 0.75em;
        width: 22rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .hero-title-underline {
        width: 0;
    }
    .explore-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transform: scale(0.9);
        margin-top: 0rem;
    }
    .explore-container2 {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transform: scale(0.9);
        margin-top: 0rem;
    }
    .explore-grid {
        margin-top: 12rem;
        margin-left: 0rem;
    }
    .explore-grid2 {
        margin-bottom: 12rem;
        margin-right: 0rem;
    }
    .questions-container {
        width: 22rem;
    }
    .landing-contact-container {
        font-size: 0.9rem;
        padding: 0.8rem 1.2rem 1.6rem 1.2rem
    }
    .contact-1 {
        width: 24rem;
    }
}

@media only screen and (min-width: 650px) and (max-width: 899px) {
    .hero-grid {
        font-size: 1rem;
        width: 80%;
    }
    .hero-title {
        width: 34rem;
    }
    .lai-text {
        width: 7rem;
    }
    .hero-desc {
        width: 30rem;
    }
    .hero-section {
        width: 100%;
        flex-direction: column;
    }
    #itemstuff {
        transform: scale(0.9);
        margin-top: 4rem;
    }
    .hero-item-container {
        justify-content: center;
    }
    .lootanditems {
        justify-content: center;
    }
    .lootanditems-cont {
        justify-content: center;
    }
    .landing-sec2-container {
        transform: scale(1);
        margin-top: 8rem;
        flex-direction: column;
        height: fit-content;
    }
    .landing-sec2-bg {
        height: 100rem;
    }
    .land-arr {
        transform: rotate(90deg);
    }
    .lsb-0 {
        height: 9rem;
        width: 9rem;
    }
    .lsb-0-circle {
        height: 6.5rem;
        width: 6.5rem;
    }
    .lsb-0-img {
        height: 4.25rem;
        width: 4.25rem;
    }
    .lsb-1 {
        height: 4.8rem !important;
        width: 4.8rem !important;
        left: 13rem !important;
    }
    .lsb-1-circle {
        height: 3.7rem !important;
        width: 3.7rem !important;
    }
    .lsb-1-img {
        height: 2rem !important;
        width: 2rem !important; 
    }
    .lsb-2 {
        height: 10rem;
        width: 10rem;
    }
    .lsb-2-circle {
        height: 8rem;
        width: 8rem;
    }
    .lsb-2-img {
        height: 5rem;
        width: 5rem;
    }
    .lsb-3 {
        height: 5rem !important;
        width: 5rem !important;
        right: 14rem !important;
    }
    .lsb-3-circle {
        height: 4.2rem !important;
        width: 4.2rem !important;
    }
    .lsb-3-img {
        height: 2.5rem !important;
        width: 2.5rem !important; 
    }
    .landing-sec2 {
        padding-top: 7rem;
    }
    .hero-title2 {
        font-size: 1.625em;
    }
    .explore-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .explore-container2 {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .explore-grid {
        margin-top: 12rem;
        margin-left: 0rem;
    }
    .explore-grid2 {
        margin-bottom: 12rem;
        margin-right: 0rem;
    }
    .questions-container {
        width: 30rem;
    }
}

@media only screen and (min-width: 900px) and (max-width: 1149px) {
    .hero-grid {
        font-size: 1.2rem;
    }
    .hero-title {
        width: 50rem;
    }
    .hero-desc {
        width: 44rem;
    }
    .hero-section {
        width: 100%;
        flex-direction: column;
    }
    #itemstuff {
        transform: scale(0.9);
        margin-top: 4rem;
    }
    .hero-item-container {
        justify-content: center;
    }
    .lootanditems {
        justify-content: center;
    }
    .lootanditems-cont {
        justify-content: center;
    }
    .landing-sec2-container {
        transform: scale(0.6);
        margin-top: 6rem;
    }
    .landing-sec2-bg {
        height: 30rem;
    }
    .lsb-0 {
        height: 10rem;
        width: 10rem;
    }
    .lsb-0-circle {
        height: 8rem;
        width: 8rem;
    }
    .lsb-0-img {
        height: 5rem;
        width: 5rem;
    }
    .lsb-1 {
        height: 5rem !important;
        width: 5rem !important;
        left: 14rem !important;
    }
    .lsb-1-circle {
        height: 4.2rem !important;
        width: 4.2rem !important;
    }
    .lsb-1-img {
        height: 2.5rem !important;
        width: 2.5rem !important; 
    }
    .lsb-2 {
        height: 10rem;
        width: 10rem;
    }
    .lsb-2-circle {
        height: 8rem;
        width: 8rem;
    }
    .lsb-2-img {
        height: 5rem;
        width: 5rem;
    }
    .lsb-3 {
        height: 5rem !important;
        width: 5rem !important;
        right: 14rem !important;
    }
    .lsb-3-circle {
        height: 4.2rem !important;
        width: 4.2rem !important;
    }
    .lsb-3-img {
        height: 2.5rem !important;
        width: 2.5rem !important; 
    }
    .landing-sec2 {
        padding-top: 7rem;
    }
    .landing-sec2-container {
        margin-top: 2rem;
    }
    .explore-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .explore-container2 {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .explore-grid {
        margin-top: 12rem;
    }
    .explore-grid2 {
        margin-bottom: 12rem;
    }
}

@media only screen and (min-width: 1150px) and (max-width: 1299px) {
    .hero-grid {
        font-size: 1rem;
    }
    .hero-section {
        width: 100%;
    }
    #itemstuff {
        transform: scale(0.9);
    }
    .landing-sec2-container {
        transform: scale(0.75);
        margin-top: 8rem;
    }
    .landing-sec2-bg {
        height: 34rem;
    }
    .lsb-0 {
        height: 10rem;
        width: 10rem;
    }
    .lsb-0-circle {
        height: 8rem;
        width: 8rem;
    }
    .lsb-0-img {
        height: 5rem;
        width: 5rem;
    }
    .lsb-1 {
        height: 5rem !important;
        width: 5rem !important;
        left: 14rem !important;
    }
    .lsb-1-circle {
        height: 4.2rem !important;
        width: 4.2rem !important;
    }
    .lsb-1-img {
        height: 2.5rem !important;
        width: 2.5rem !important; 
    }
    .lsb-2 {
        height: 10rem;
        width: 10rem;
    }
    .lsb-2-circle {
        height: 8rem;
        width: 8rem;
    }
    .lsb-2-img {
        height: 5rem;
        width: 5rem;
    }
    .lsb-3 {
        height: 5rem !important;
        width: 5rem !important;
        right: 14rem !important;
    }
    .lsb-3-circle {
        height: 4.2rem !important;
        width: 4.2rem !important;
    }
    .lsb-3-img {
        height: 2.5rem !important;
        width: 2.5rem !important; 
    }
    .landing-sec2-container {
        margin-top: 4rem;
    }
}

@media only screen and (min-width: 1300px) and (max-width: 1440px) {
    .hero-section {
        width: 100%;
    }
    .landing-sec2-container {
        transform: scale(0.85);
    }
    .landing-sec2-container {
        margin-top: 6rem;
    }
}