.n-book-options-container {
    background-color: #F5F5F5;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.n-book-options-box {
    height: 50rem;
    width: 58rem;
    border: 1px solid #C6C8C9;
    background-color: #FCFBFC;
    border-radius: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.n-book-options-left {
    height: 100%;
    width: 32%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding: 2.5rem 2rem 2.5rem 2rem;
    box-sizing: border-box;
    position: relative;
}

.n-book-options-right {
    height: 96%;
    width: 68%;
    margin-right: 1.5%;
    border: 1px solid rgb(231, 231, 231);
    border-radius: 0.8rem;
    background-color: white;
    display: flex;
    box-sizing: border-box;
    padding: 1.5rem 2rem 1.5rem 2rem;
}

.n-book-options-main-header {
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 13rem;
    position: relative;
    font-size: 0.9rem;
    color: #52637D;
}

.n-book-options-main-abs {
    position: absolute;
    left: 0;
    top: 116%;
    color: #9BA4B6;
    font-size: 0.8125rem;
}

.n-temp-txt {
    color: #9BA4B6;
    font-size: 0.7rem;
    margin-top: 3rem;
    line-height: 1.4rem;
    display: flex;
    flex-direction: column;
    justify-content: left;
}

.n-menu-option-btn-active {
    display: flex;
    justify-content: left;
    align-items: center;
    border: none;
    border-radius: 0.625rem;
    padding: 0.7rem 1rem 0.7rem 1rem;
    color: white;
    font-family: "Inter";
    background-color: #27AE85;
    font-weight: 500;
    margin-top: 0.2rem;
    font-size: 0.8125rem;
    cursor: pointer;
}

.n-menu-option-btn {
    display: flex;
    justify-content: left;
    align-items: center;
    border: none;
    border-radius: 0.625rem;
    padding: 0.7rem 1rem 0.7rem 1rem;
    color: #52637D;
    font-family: "Inter";
    font-weight: 400;
    transition: background-color 0.3s ease-in-out;
    margin-top: 0.2rem;
    font-size: 0.8125rem;
}

.n-menu-option-btn:hover {
    background-color: rgb(241, 241, 241);
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.n-entry-title {
    color: #52637D;
    font-size: 0.9rem;
    font-weight: 500;
}

.n-book-preview-bg {
    background-color: #FDFDFD;
    border: 1px solid #B7B7B7;
    border-radius: 0.8rem;
    margin-top: 2rem;
    box-sizing: border-box;
    padding: 1.4rem 0 1.4rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.n-sticker-container {
    margin-top: 0.625rem;
    border: 1px solid #C6C8C9;
    border-radius: 0.8rem;
    padding: 1rem;
}

.n-sticker-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
}

.n-sticker-item {
    padding: 0.2rem;
    border-radius: 0.4rem;
    border: 1px solid #B7B7B7;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.4rem;
    height: 2.6rem;
    margin: 0.4rem;
    transition: background-color 0.3s ease-in-out;
}

.n-sticker-item:hover {
    cursor: pointer;
    background-color: #f1f1f1;
    transition: background-color 0.3s ease-in-out;
}

.n-sticker-item-active {
    padding: 0.2rem;
    border-radius: 0.4rem;
    border: 1px solid #1d754e;
    background-color: #78C6A3;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.4rem;
    height: 2.6rem;
    margin: 0.4rem;
    transition: background-color 0.3s ease-in-out;
}

.n-sticker-item-active:hover {
    cursor: pointer;
    background-color: #90d1bb;
    transition: background-color 0.3s ease-in-out;
}

.n-sticker-item-unavail {
    padding: 0.2rem;
    border-radius: 0.4rem;
    border: 1px solid #B7B7B7;
    background-color: rgb(236, 236, 236);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.4rem;
    height: 2.6rem;
    margin: 0.4rem;
    transition: background-color 0.3s ease-in-out;
    cursor: not-allowed;
}

.n-sticker-item-locked {
    padding: 0.2rem;
    border-radius: 0.4rem;
    border: 1px solid #B7B7B7;
    background-color: rgb(236, 236, 236);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.4rem;
    height: 2.6rem;
    margin: 0.4rem;
    filter: grayscale(0.8);
    transition: background-color 0.3s ease-in-out;
    position: relative;
}

.n-sticker-item-locked:hover {
    cursor: not-allowed;
}

.n-slidecontainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: relative;
  }
  
  .n-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;  
    background: transparent;
    outline: none;
    opacity: 1;
    -webkit-transition: .2s;
    transition: opacity .2s;
    z-index: 20;
  }
  
  .n-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 28px;
    height: 28px;
    border-radius: 50%; 
    background: #27AE85;
    cursor: pointer;
    z-index: 20;
  }
  
  .n-slider::-moz-range-thumb {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #27AE85;
    cursor: pointer;
    z-index: 20;
  }

  .n-log-activity {
    background-color: #27AE85;
    padding: 0.625rem 1rem 0.625rem 1rem;
    border-radius: 0.4rem;
    border: none;
    color: white;
    font-weight: 400;
    width: 100%;
    box-sizing: border-box;
    font-family: "Inter";
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  }

  .n-log-activity:hover {
    cursor: pointer;
    background-color: #1c917d;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  }

  .n-log-activity:active {
    transform: scale(0.98);
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  }

  .shadow-input {
    position: absolute;
    height: 70%;
    width: 100%;
    background-color: #EFEFEF;
    z-index: 12;
    border-radius: 0.8rem;
  }

  .shadow-input-fill {
    position: absolute;
    height: 100%;
    width: 0%;
    background-color: #27AE85;
    border-radius: 0.8rem;
  }

  .n-back-btn {
    padding: 0.4rem 0.8125rem 0.4rem 0.8125rem;
    transition: background-color 0.3s ease-in-out;
  }

  .n-back-btn:hover {
    cursor: pointer;
    border-radius: 0.4rem;
    background-color: rgb(238, 238, 238);
    transition: background-color 0.3s ease-in-out;
  }