.n-register-bg {
    background-color: #F5F5F5;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.n-register-box {
    background-color: white;
    border-radius: 2rem;
    padding: 1rem;
    width: 70rem;
    height: 48rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0.86);
    position: relative;
    border: 1px solid #c0c6ce;
    box-shadow: 0px 0px 20px rgb(196, 196, 202);
}

.n-register-left-sec {
    height: 100%;
    width: 35%;
    background-color: #F7F8FA;
    border-radius: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.n-register-right-sec {
    height: 100%;
    width: 65%;
    background-color: white;
    display: flex;
    justify-content: center;
}

.n-register-logo-section {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 4rem;
    margin-bottom: 5rem;
}

.n-register-logo-section2 {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 2rem;
    margin-left: -0.4rem;
}

.logo-title {
    color: #06AB78;
    font-size: 1rem;
    padding-left: 0.3rem;
    cursor: pointer;
}

.n-register-sections-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.n-register-lvl-flex {
    display: flex;
    justify-content: left;
    width: 100%;
}

.n-register-lvl-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.1125rem;
    width: 2.1125rem;
    border-radius: 0.4rem;
    border: 1px solid #E6E6E6;
    background-color: white;
    position: relative;
    z-index: 20;
}

.n-register-lvl-box-current {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.1125rem;
    width: 2.1125rem;
    border-radius: 0.4rem;
    border: 1px solid #009768;
    background-color: white;
    position: relative;
    z-index: 20;
}

.n-register-lvl-box-complete {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.1125rem;
    width: 2.1125rem;
    border-radius: 0.4rem;
    border: 1px solid #009768;
    background-color: #06AB78;
    position: relative;
    z-index: 20;
    animation: n-complete 0.6s ease-in-out 1;
}

@keyframes n-complete {
    0% {
        transform: scale(0.8);
    }
    75% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

.n-register-lvl-0-complete {
    color:#06AB78;
    font-weight: 600;
    font-size: 0.8125rem;

}

.n-register-lvl-1-complete {
    color: #59B99B;
    font-size: 0.66015625rem;
    margin-top: 0.2rem;
 
}

.n-register-lvl-0-incomplete {
    color:#777D86;
    font-weight: 600;
    font-size: 0.8125rem;
}

.n-register-lvl-1-incomplete {
    color: #9399A1;
    font-size: 0.66015625rem;
    margin-top: 0.2rem;

}

.n-register-lvl-line-incomplete {
    position: absolute;
    width: 3px;
    height: 3.25rem;
    background-color: #E6E6E6;
    top: 106%;
    z-index: 2;
}

.n-register-lvl-line-complete {
    position: absolute;
    width: 3px;
    height: 3.2rem;
    background-color: #06AB78;
    top: 100%;
}

.n-register-shadow-logo {
    position: absolute;
    filter: grayscale(1) opacity(0.15);
    height: 20rem;
    bottom: 5%;
    right: -40%;
    transform: rotate(-40deg);
}

.n-register-right-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 55%;
    height: 100%;
    position: relative;
}

.n-register-top-0 {
    color:#06AB78;
    font-weight: 600;
    font-size: 1.4rem;
    margin-top: 0.8125rem;
}

.n-register-top-1 {
    color: #727E90;
    font-size: 0.9rem;
    margin-top: 0.2rem;
}

.n-register-right-progress-flex {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 5%;
}

.n-progress-active {
    height: 0.4rem;
    width: 5rem;
    border-radius: 0.8rem;
    background-color: #06AB78;
    margin-right: 0.4rem;
    margin-left: 0.4rem;
    animation: n-complete 0.6s ease-in-out 1;
}

.n-progress-inactive {
    height: 0.4rem;
    width: 5rem;
    border-radius: 0.8rem;
    background-color: #D9D9D9;
    margin-right: 0.4rem;
    margin-left: 0.4rem;
    transition: background-color 0.3s ease-in-out;
}

.n-progress-inactive:hover {
    cursor: pointer;
    background-color: #cacaca;
    transition: background-color 0.3s ease-in-out;
}

.n-register-left-back {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: calc(5% - 0.4rem);
    font-weight: 600;
    font-size: 0.8125rem;
    padding: 0.4rem;
    border-radius: 0.4rem;
    transition: background-color 0.3s ease-in-out;
}

.n-register-left-back:hover {
    cursor: pointer;
    background-color: rgb(228, 228, 228);
    transition: background-color 0.3s ease-in-out;
}

.n-register-left-sign-in {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    padding: 0.4rem;
    border-radius: 0.4rem;
    bottom: calc(5% - 0.4rem);
    font-weight: 600;
    font-size: 0.8125rem;
    transition: background-color 0.3s ease-in-out;
}

.n-register-left-sign-in:hover {
    cursor: pointer;
    background-color: rgb(228, 228, 228);
    transition: background-color 0.3s ease-in-out;
}

.n-register-error {
    position: absolute;
    top: 110%;
    left: 1%;
    font-size: 0.8125rem;
    color: rgb(231, 108, 104);
}

.n-code-input {
    height: 4.5rem;
    width: 1.8rem;
    border-radius: 0.8rem;
    border-color: transparent;
    background-color: transparent;
    outline: transparent;
    font-size: 3rem;
    font-family: "Inter";
    color: #06AB78;
    font-weight: 600;
}

.n-register-code-box {
    border: 1px solid #5BCBA3;
    border-radius: 0.8rem;
    height: 5rem;
    width: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
}

.n-register-code-box-invalid {
    border: 1px solid rgb(233, 109, 92);
    border-radius: 0.8rem;
    height: 5rem;
    width: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
}

.n-verify-btn {
    background-color: #06AB78;
    width: 45%;
    margin-top: 2rem;
    height: 2.625rem;
    border: none;
    border-radius: 8px;
    color: white;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.n-verify-btn:hover {
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
    background-color: #059773;
}

.n-verify-btn:active {
    transform: scale(0.95);
    transition: transform 0.3s ease-in-out;
}

.n-go-back-btn {
    border: 1px solid #aeb8c5;
    background-color: white;
    width: 45%;
    margin-top: 2rem;
    height: 2.625rem;
    border-radius: 8px;
    color: #454b54;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
    margin-right: 10%;
}

.n-go-back-btn:hover {
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
    background-color: #f3f3f3;
}

.n-go-back-btn:active {
    transform: scale(0.95);
    transition: transform 0.3s ease-in-out;
}

.n-avid-box {
    border: 1px solid #D9D9D9;
    padding: 1.4rem;
    border-radius: 0.8rem;
    width: 38rem;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    cursor: pointer;
}

.n-avid-box-active {
    border: 1px solid #06AB78;
    padding: 1.4rem;
    border-radius: 0.8rem;
    width: 38rem;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    animation: n-complete 0.6s ease-in-out 1;
    cursor: pointer;
}

.n-register-main-text {
    font-size: 1.4rem;
    font-weight: 600;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}