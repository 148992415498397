html, body {

    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
    margin: 0;
    font-family: "Inter";
    color: #454B54;
    user-select: none;
    
}

h3 {
  font-family: "Inter";
  font-size: 16px;
  color: white;
  font-weight: 500;
  margin-right: 2px;
}

a {
  text-decoration: none;
}

h1 {

    font-size: 56px;
    font-weight: bold;
    color: #30313D;

}

h4 {

    font-size: 14px;
    font-weight: 300;
    color: #30313D;

}

h5 {

    font-size: 12px;
    font-weight: 300;
    color: #30313D;

}

green {

    color: #06ab78;

}

.navBar {

    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;

}

.mainContentContainer {

    display:flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;

}

.mainContentGrid {

    display: grid;
    grid-template-columns: 1fr;
    margin-top: 25px;

}

.flexMiddle {

    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;

}

.flexRight {

    display: flex;
    justify-content: right;
    align-items: center;

}

.flexLeft {

    display: flex;
    justify-content: left;
    align-items: center;
    height: 100%;
    width: 100%;

}

.searchContainer {

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: 100px;

}

.searchGrid {

    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    width: 100%;


}

.searchCircle {

    background-color: #06ab78;
    border-radius: 100%;
    height: 27px;
    width: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-left: 5px;
    left: 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
    transition: height 0.3s ease, width 0.3s ease;

}

.svg {

    width: 60%;
    height: 60%;
    transition: opacity 0.3s ease;

}

.searchCircle:hover {

    transition: height 0.3s ease, width 0.3s ease;
    height: 30px;
    width: 30px;

}

.noOutline {

    outline: none;
    border: none;
    border-style: none;

}

.noBoldBorder {

    outline: none;

}

.searchHistory {

    box-shadow: 0px 10px 20px rgb(196, 196, 196);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

}

.historyEntry:hover {

    background-color: #c1eee0;
    cursor: default;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    width: 100%;

}

.searchBoxShadow {

   
    border-radius: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

}

.historyEntry {

    padding-top: 5px;
    padding-bottom: 5px;
    margin-right: 50px;

    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 100%;
    position: relative;

}

.searchBorderRadius {

    border-radius: 100px;

}

.XHistory {

    position: absolute;
    right: 0;
    margin-right: 15px;
    cursor: pointer;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.XHistory:hover {

    background-color: #88cfb9;
    border-radius: 100%;
    

}

.activeGreen {
    color: #06ab78;
    font-size: 16px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    font-weight: 700;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 40px;
    margin-right: 40px;
    cursor: pointer;
}

.unactiveBlack {
    color: #969696;
    font-size: 16px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    font-weight: 600;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    margin-left: 40px;
    margin-right: 40px;
    transition: color 0.3s ease;
}

.unactiveBlack:hover {

    transition: color 0.3s ease;
    color:#30c495;

}

.dailyQuestsContainer {

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    height: 100%;

}

.questToken {

    display: flex;
    align-items: center;
    background-color: #F8F9FD;
    width: 700px;
    height: 50px;
    border-radius: 30px;
    margin: 20px;

}


.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #06AB78;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }

  .circleStroke {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    stroke: #06AB78;
    stroke-width: 2;
  }
  
  .checkmark {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #06AB78;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  }
  
  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
  
  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes scale {
    0%, 100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px #06AB78;
    }
  }

  .emptyCheckBox {

    position: absolute;
    height: 36px;
    width: 36px;
    border-radius: 30px;
    border-color: #06AB78;
    border-width: 2px;
    border-style: solid;
    right: 0;
    transition: background-color 0.3s ease-in;
  }

  .emptyCheckBox:hover {
    background-color: #06AB78;
    transition: background-color 0.3s ease-in;
    cursor: pointer;
  }

  .checkStar {
    animation: starShake 2s infinite ease-in-out;
  }

  @keyframes starShake {

    20%{
        transform: translateY(-5px)
    }

    40%{
        transform: rotate(30deg);       
    }

    60%{
        transform: rotate(-30deg)

    }

  }

  .ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
  }
  .ribbon::before,
  .ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #04855c;
  }
  .ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color: #06ab78;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #fff;
    font: 700 18px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;
  }
  
/* top left*/

  .ribbon-top-left::before,
  .ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
  }
  .ribbon-top-left::before {
    top: 0;
    right: 0;
  }
  .ribbon-top-left::after {
    bottom: 0;
    left: 0;
  }
  .ribbon-top-left span {
    right: -25px;
    top: 30px;
    transform: rotate(-45deg);
  }

  .ribbonScale {
    transform: scale(0.3);
    left: -82px;
    top: -56px;
  }

  .loginButton {
    background-color: #06ab78;
    border: none;
    color: white;
    padding: 10px;
    margin-right: 10px;
    border-radius: 10px;
  }

  .loginButton:hover {
    background-color: #30c495;
  }

  .loginGrid {
    display: grid;  
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }

  .inputBox {
    height: 30px;
    width: 300px;
    border-width: 1px;
    border-radius: 5px;
    outline-color: #0969da;
    border-color: rgb(184, 184, 184);
    border-style: solid;
    padding-left: 10px;
  }

  .submitButton {
    color: white;
    font-weight: bold;
    background-color: #06ab78;
    border: none;
    border-radius: 5px;
    height: 35px;
    width: 300px;
    transition: background-color 0.3s ease-in-out;
  }

  .submitButton:hover {
    cursor: pointer;
    background-color: #30c495;
    transition: background-color 0.3s ease-in-out;
  }

  .loginContainer {
    padding: 30px;
    background-color: #f6f8fa;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(221, 221, 221);
    border-radius: 10px;
  }

  .newUserContainer {
    padding: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(221, 221, 221);
    border-radius: 10px;
    margin-top: 20px;
    display: grid;
    justify-content: center;
    align-items: center;
    width: 75%;
  }

  .clickLink {

    font-size: 12px;
    color: #0969da;
    cursor: pointer;

  }

  .separator {
    height: 1px;
    width: 40px;
    background-color: rgb(221, 221, 221);
    margin-left: 2px;
    margin-right: 2px;
  }

  .circle {
    height: 3px;
    width: 3px;
    border-radius: 100%;
    background-color: rgb(221, 221, 221);
  }

  .errorGrid {
    display: grid;
    grid-template-columns: 1fr, 1fr;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  }

  .errorHeading {

    color:#06ab78;
    font-size: 40px;
    font-weight: bold;

  }

  .errorSubText {

    font-size: 16px;
    font-weight: 400;

  }

  #link {
    color: #969696;
  }


.triangleActive {
  width: 0;
  height: 0;
  border-bottom: 70px solid #06AB78;
  border-left: 80px solid transparent;
  cursor: pointer;
  z-index: 100;
}

.triangleUnactive {
  width: 0;
  height: 0;
  border-bottom: 70px solid #F0F0F0;
  border-left: 80px solid transparent;
  cursor: pointer;
  z-index: 100;
}

.rectangleActive {

  background-color: #06AB78;
  color: white;
  padding: 10px 20px;
  height: 50px;
  width: 140px;
  line-height: 60px;
  font-family: "Inter";
  font-weight: 500;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
  z-index: 100;
}

.rectangleUnactive {

  background-color: #F0F0F0;
  color: #717171;
  padding: 10px 20px;
  height: 50px;
  width: 140px;
  line-height: 60px;
  font-family: "Inter";
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
  z-index: 100;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
