.library-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #F2F2F2;
    height: 100%;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
}

.library-box {
    display: flex;
    justify-content: center;
    height: 50rem;
    width: 90rem; 
}

.n-new-nav2 {
    height: 4rem;
    width: 100%;
    background-color: #78C6A3;
    border-radius: 0.8rem;
    border: 1px solid #454B54;
    margin-top: 2rem;
    box-shadow: 0 4px 6px #bdbdbd;
}

.n-new-nav {
    height: 4rem;
    width: 85rem;
    background-color: #78C6A3;
    border-radius: 0.8rem;
    border: 1px solid #454B54;
    margin-top: 2rem;
    box-shadow: 0 4px 6px #bdbdbd;
}

.calendar-grid {
    display: grid;
    grid-template-columns: repeat(20, 0.8rem);
    align-items: center;
    gap: 0.15rem;
    width: 100%;
}

.n-book-not-found-cont {
    border: 1px solid #B8B8B8;
    padding: 0.6rem 1rem 0.6rem 1rem;
    border-radius: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.n-book-not-found-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 17rem;
    margin-left: 0.625rem;
    font-weight: 600;
}

.kwat {
    color: #8D8D8D;
    font-weight: 500;
    font-size: 0.8125rem;
    margin-top: 0.4rem;
}

.n-new-nav-l {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 80%;
    height: 100%;
}

.nn-item {
    padding: 0rem 1rem 0rem 1rem;
    border-radius: 0.8rem;
    color: white;
    font-weight: 600;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nn-item:hover {
    cursor: pointer;
    background-color: #69b698;
}

.week-row {
    width: fit-content;
    position: relative;
}

.n-streak-months {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.2rem;
}

.calendar-legend {
    display: flex;
    justify-content: right;
    align-items: center;
    width: 100%;
    margin-top: 0.7rem;
}

.legend-text {
    font-size: 0.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.n-week {
    position: absolute;
    right: 140%;
    font-size: 0.7rem;
    width: 2rem;
}

.n-month {
    font-size: 0.7rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.filled {
    height: 0.7rem;
    width: 0.7rem;
    background-color: #78C6A3;
    border: 1px solid #06AB78;
    margin-top: 0.1rem;
    border-radius: 0.2rem;
    transition: background-color 0.1s ease-in-out;
}

.filled:hover {
    cursor: pointer;
    background-color: #06AB78;
    transition: background-color 0.1s ease-in-out;
}

.missed {
    height: 0.7rem;
    width: 0.7rem;
    background-color: #ee8c9c;
    border: 1px solid #e46969;
    margin-top: 0.1rem;
    border-radius: 0.2rem;
    transition: background-color 0.1s ease-in-out;
}

.missed:hover {
    cursor: pointer;
    background-color: #e46969;
    transition: background-color 0.1s ease-in-out;
}

.soon {
    height: 0.7rem;
    width: 0.7rem;
    background-color: transparent;
    border: 1px solid rgb(243, 243, 243);
    margin-top: 0.1rem;
    border-radius: 0.2rem 
}

.empty {
    height: 0.7rem;
    width: 0.7rem;
    background-color: #E8E8E8;
    border: 1px solid #d3d3d3;
    margin-top: 0.1rem;
    border-radius: 0.2rem;
    transition: background-color 0.1s ease-in-out;
}

.empty:hover {
    cursor: pointer;
    background-color: #d3d3d3;
    transition: background-color 0.1s ease-in-out;
}

.n-goals-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    margin-top: 1rem;
    width: 100%;
    margin-left: 4rem;
}

.n-goals-box-active {
    border: 1px solid gray;
    border-radius: 0.8rem;
    padding: 0.625rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 18rem;
    margin-top: 1rem;
    position: relative;
}

.n-goals-box-inactive {
    border: 1px solid gray;
    border-radius: 0.8rem;
    padding: 0.625rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 18rem;
    margin-top: 1rem;
    position: relative;
    transition: transform 0.3s ease-in-out;
}

.n-goals-box-inactive:active {
    transform: scale(0.95);
    transition: transform 0.3s ease-in-out;
}

.n-goals-x {
    position: absolute;
    left: 107%;
}

.n-goals-box-top {
    display: flex;
    align-items: center;
}

.n-goals-box-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.625rem;
    width: 100%;
}

.n-goals-fill-bar {
    height: 0.625rem;
    width: 80%;
    background-color: #E8E8E8;
    border-radius: 0.8rem;
}

.n-goals-fill {
    width: 100%;
    height: 0.625rem;
    background-color: #78C6A3;
    border-radius: 0.8rem;
}

.n-goals-check {
    position: absolute;
    top: 7%;
    right: 3%;
    height: 1.625rem;
    width: 1.625rem;
    border-radius: 0.4rem;
    border: 1px solid #454B54;
    background-color: white;
}

.n-goals-quant-active {
    margin-left: 0.625rem;
    color:#78C6A3;
    font-size: 0.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.n-goals-quant-inactive {
    margin-left: 0.625rem;
    color:#9D9D9D;
    font-size: 0.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.n-goals-circle-active {
    background-color: #78C6A3;
    border: 1px solid #06AB78;
    height: 4rem;
    width: 4rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.n-goals-circle-inactive {
    background-color: #E8E8E8;
    border: 1px solid #C5C5C5;
    height: 4rem;
    width: 4rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.n-goals-circle-img {
    height: 3.4rem;
    margin-top: 0.4rem;
}

.n-goals-box-info {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    margin-left: 1rem;
}

.n-goals-box-info-title-active {
    font-weight: 600;
    color:#06AB78;
    margin-bottom: 0.4rem;
}

.n-goals-box-info-title-inactive {
    font-weight: 600;
    color:#454b54;
    margin-bottom: 0.4rem;
}

.n-goals-box-info-desc {
    color: #9D9D9D;
    font-size: 0.7rem;
    font-weight: 500;
}

.n-goals-box-info-desc-none {
    color: #9D9D9D;
    font-size: 0.7rem;
    font-weight: 500;
}

.n-library-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 29%;
}

.filter-list {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    margin-top: 1.5rem;
    margin-left: 4rem;
    height: fit-content;
}

.filter-bar {
    width: 1px;
    height: 80%;
    background-color: gray;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
}

.filter-item {
    padding: 0.3rem 0.75rem 0.3rem 0.75rem;
    border-radius: 0.8rem;
    background-color: #F4F4F4;
    border: 1px solid #D8D8D8;
    font-size: 0.7rem;
    margin-right: 0.625rem;
    transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out;
}

.filter-item:hover {
    cursor: pointer;
    background-color: #e6e6e6;
    border: 1px solid #bebebe;
    transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out;
}

.filter-item-active {
    padding: 0.3rem 0.5rem 0.3rem 0.75rem;
    border-radius: 0.8rem;
    background-color: #A6D8C2;
    border: 1px solid #368B65;
    font-size: 0.7rem;
    margin-right: 0.625rem;
    transition: background-color 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

.filter-item-active:hover {
    cursor: pointer;
    background-color: #9bcebd;
    transition: background-color 0.3s ease-in-out;
}

.filter-search {
    border: 1px solid #D9D9D9;
    border-radius: 0.8rem;
    height: 1.9rem;
    margin-top: 1rem;
    width: 80%;
    padding-left: 1rem;
    font-family: "Inter";
    color: #454b54;
}

.filter-search:focus {
    outline: 1px solid #bbbbbb;
}

.filter-search::placeholder {
    font-family: "Inter";
}

.n-reading-entry-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    width: 100%;
}

.reading-entry-item-cont {
    width: 100%;
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* HTML: <div class="loader"></div> */
.loader3 {
    width: 160px;
    height: 20px;
    border-radius: 0.8rem;
    background: 
      linear-gradient(90deg,rgba(78, 78, 78, 0.067) 33%,rgba(68, 68, 68, 0.333) 50%,rgba(78, 78, 78, 0.067) 66%)
      #f2f2f2;
    background-size:300% 100%;
    animation: l1 1s infinite linear;
  }
  @keyframes l1 {
    0% {background-position: right}
  }

  .loader3_med {
    width: 130px;
    height: 20px;
    border-radius: 0.8rem;
    background: 
      linear-gradient(90deg,rgba(78, 78, 78, 0.067) 33%,rgba(68, 68, 68, 0.333) 50%,rgba(78, 78, 78, 0.067) 66%)
      #f2f2f2;
    background-size:300% 100%;
    animation: l1 1s infinite linear;
  }
  @keyframes l1 {
    0% {background-position: right}
  }

.loader3_small {
    width: 70px;
    height: 20px;
    border-radius: 0.8rem;
    background: 
    linear-gradient(90deg,rgba(78, 78, 78, 0.067) 33%,rgba(68, 68, 68, 0.333) 50%,rgba(78, 78, 78, 0.067) 66%)
      #f2f2f2;
    background-size:300% 100%;
    animation: l1 1s infinite linear;
}

.loader3_thin {
    width: 70px;
    height: 16px;
    border-radius: 0.8rem;
    background: 
    linear-gradient(90deg,rgba(78, 78, 78, 0.067) 33%,rgba(68, 68, 68, 0.333) 50%,rgba(78, 78, 78, 0.067) 66%)
      #f2f2f2;
    background-size:300% 100%;
    animation: l1 1s infinite linear;
}

.n-entry-dots-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 0.8125rem;
}

.n-dot-empty {
    height: 0.6125rem;
    width: 0.6125rem;
    background-color: #e9e9e9;
    border: 1px solid #dfdfdf;
    border-radius: 100%;
    margin: 0.1rem;
}

.n-dot-empty:hover {
    cursor: pointer;
    background-color: #dfdfdf;
    border: 1px solid #d3d3d3;
    transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out;
}

.n-dot-full {
    height: 0.8125rem;
    width: 0.8125rem;
    background-color: #78C6A3;
    border: 1px solid #06AB78;
    border-radius: 100%;
    margin: 0.1rem
}

.n-dot-full:hover {
    cursor: pointer;
}

.n-reading-entry-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-right: 2rem;
}

.n-reading-entry-circle {
    height: 3.2rem;
    width: 3.2rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.n-file-claim {
    animation: n-file-dance 1.5s ease-in-out infinite;
}

@keyframes n-file-dance {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(10deg);
    }
    75% {
        transform: rotate(-10deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.n-reading-entry-info {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    font-size: 0.9rem;
    width: 14rem;
    margin-left: 1rem;
    position: relative;
}

.n-entry-date {
    position: absolute;
    bottom: 0;
    right: -2rem;
    font-size: 0.7em;
    color: #7E7E7E;
}

.n-ret {
    font-size: 1em;
    font-weight: 700;
}

.n-tag {
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    border: 1px solid rgb(167, 167, 167);
    border-radius: 0.8rem;
    width: fit-content;
    display: flex;
    place-items: center;
    font-size: 0.7rem;
    margin-top: 0.4rem;
    font-weight: 500;
}

.n-library-sep {
    width: 3.5%;
}

.n-library-right {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 67%;
    border-radius: 0.8rem;
}

.n-library-main {
    height: 80%;
    width: 100%;
    border: 1px solid #7E7E7E;
    background-color: #FAFAFA;
    border-radius: 0.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 6px #bdbdbd;
}

.n-library-wide {
    height: 28%;
    width: 100%;
    border: 1px solid #7e7e7e;
    background-color: #FAFAFA;
    border-radius: 0.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.n-library-box-med {
    height: 78%;
    width: 100%;
    background-color: #FAFAFA;
    border-radius: 0.8rem;
    border: 1px solid #7E7E7E;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    box-shadow: 0px 4px 6px rgb(189, 189, 189);
}

.filter-num {
    font-size: 0.8125rem;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;
}

.filter-num-title {
    font-weight: 500;
    color: #454B54;
}

.n-library-box-small {
    height: 25%;
    width: 100%;
    background-color: #78C6A3;
    border-radius: 0.8rem;
    border: 1px solid #7E7E7E;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: 0px 4px 6px rgb(189, 189, 189);
}

.n-library-box-sep {
    height: 3%;
    width: 100%;
}

.n-library-banner {
    height: 20%;
    width: 100%;
    background-color: #78C6A3;
    border-radius: 0.8rem;
    border: 1px solid #06AB78;
    box-shadow: 0px 4px 6px rgb(207, 207, 207);
    display: flex;
    justify-content: left;
    align-items: center;
}

.n-library-banner2 {
    height: 4rem;
    width: 100%;
    background-color: #78C6A3;
    border-radius: 0.8rem;
    border: 1px solid #06AB78;
    box-shadow: 0px 4px 6px rgb(194, 194, 194);
    display: flex;
    justify-content: left;
    align-items: center;
    z-index: 9999;
    position: relative
}

.n-library-input {
    height: 2rem;
    border: 1px solid #06AB78;
    border-radius: 0.8rem;
    font-family: "Inter";
    color: #5A5A5A;
    width: 15rem;
    padding-left: 4.5rem;
}

.n-library-input:focus {
    outline: none;
    border: 2px solid #06AB78;
}

.n-library-input::placeholder {
    font-family: "Inter";
}

.n-banner-img {
    height: 3.4rem;
    margin-left: 3rem;
}

.n-library-banner-info {
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: left;
    margin-left: 1rem;
    color: white;
    font-size: 0.7rem;
}

.n-banner-title {
    font-weight: 600;
    font-size: 1.4rem;
}

.n-streak-container {
    display: flex;
    justify-content: center;
}

.n-streak-seg {
    height: 1px;
    width: 80%;
    background-color: rgb(172, 172, 172);
    margin-top: 1.4rem;
    margin-bottom: 0.7rem;
}

.n-streak-circle {
    height: 4.6rem;
    width: 4.6rem;
    background-color: #FAFAFA;
    border: 1px solid #06AB78;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.streak-tiny-circle1 {
    position: absolute;
    top: 0.625rem;
    left: 0.8125rem;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 100%;
    background-color: white;
}

.streak-tiny-circle2 {
    position: absolute;
    top: 0.625rem;
    right: 0.8125rem;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 100%;
    background-color: white;
}

/* The switch - the box around the slider */
.n-switch-container {
    position: absolute;
    height: 24px;
    left: 0.8125rem;
}

.switch {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 5px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #27AE85;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    margin-left: -0.35rem;
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
    margin-bottom: -0.15rem;
    margin-left: -0.05rem;
  }

.n-library-input-container {
    position: relative;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
}

.n-library-chevron-circle {
    height: 2.4rem;
    width: 2.4rem;
    border-radius: 100%;
    background-color: #78C6A3;
    border: 1px solid #06AB78;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 6px rgb(203, 203, 203);
    animation: chevron-anim 1.4s ease-in-out infinite;
    transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out;
}

.n-library-chevron-circle:hover {
    cursor: pointer;
    background-color: #67b79b;
    border: 1px solid #049682;
    transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out;
}

@keyframes chevron-anim {
    0% {
        transform: translateY(0%);
    }
    40% {
        transform: translateY(0.665rem);
    }
    50% {
        transform: translateY(0.7rem);
    }
    100% {
        transform: translateY(0%);
    }
}

.n-library-options-left {
    width: 50%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.n-library-options-right {
    width: 50%;
    display: flex;
    justify-content: right;
    align-items: center;
}

.option-right-item {
    font-size: 0.8125rem;
    font-weight: 600;
    color: #06AB78;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0.6rem 0.25rem 0.6rem;
    border-radius: 0.4rem;
    transition: background-color 0.3s ease-in-out;
}

.option-right-item:hover {
    cursor: pointer;
    background-color: rgb(230, 230, 230);
    transition: background-color 0.3s ease-in-out;
}

.n-library-books-container {
    height: 76%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

.n-library-books-container::-webkit-scrollbar-button {
    display: none;
}

.n-library-books-container::-webkit-scrollbar {
    width: 0.4rem;
}

.n-library-books-container::-webkit-scrollbar-track {
    background-color: #D9D9D9;
    border-radius: 0.8rem;
}

.n-library-books-container::-webkit-scrollbar-thumb {
    background-color: #78C6A3;
    border-radius: 0.8rem;
}

.n-library-chevron {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-grow: 1;
}

.n-library-books-grid {
    height: fit-content;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    row-gap: 2rem;
    place-items: center;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.n-lighter-img {
    height: 4.6rem;
    margin-right: 1rem;
    margin-bottom: 0.4rem;
}

.n-lighter-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    color: #F5F5F5;
    font-size: 0.7rem;
    width: 14rem;
    margin-left: 1rem;
    margin-top: 0.4rem;
}

.n-lighter-title {
    color: white;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.2rem;
}

/* HTML: <div class="loader"></div> */
.loader-circle {
    width: 50px;
    padding: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #25b09b;
    --_m: 
      conic-gradient(#0000 10%,#000),
      linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
            mask: var(--_m);
    -webkit-mask-composite: source-out;
            mask-composite: subtract;
    animation: l3 1s infinite linear;
  }
  @keyframes l3 {to{transform: rotate(1turn)}}

  .loader-circle-white {
    width: 50px;
    padding: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: rgb(236, 236, 236);
    --_m: 
      conic-gradient(#0000 10%,#000),
      linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
            mask: var(--_m);
    -webkit-mask-composite: source-out;
            mask-composite: subtract;
    animation: l3 1s infinite linear;
  }
  @keyframes l3 {to{transform: rotate(1turn)}}

.n-lighter-btn {
    background-color: rgb(255, 255, 255);
    border: 1px solid #454b54;
    border-radius: .4rem;
    color: #78C6A3;
    font-family: Inter;
    font-weight: 600;
    font-size: 0.7rem;
    margin-top: 1.4rem;
    padding: .5rem 0;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.n-create-entry-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F2F2F2;
}

.n-create-entry-cont {
    width: 40rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.n-mbs {
    color: #06AB78;
    font-weight: 600;
    font-size: 0.9rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
}

.n-entry-slider-abs {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    background-color: #78C6A3;
    z-index: 999;
    border-radius: 0.8rem;
    height: 80%;
}

.n-ce-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28rem;
    margin-bottom: 1rem;
}

.okk {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.4rem 0.8rem 0.4rem 0.8rem;
    border-radius: 0.4rem;
    transition: background-color 0.3s ease-in-out;
}

.okk:hover {
    cursor: pointer;
    background-color: rgb(231, 231, 231);
    transition: background-color 0.3s ease-in-out;
}

.n-ce-nav-l {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 50%;
    font-size: 0.7rem;
    font-weight: 600;
}

.n-ce-nav-r {
    display: flex;
    justify-content: right;
    align-items: center;
    width: 50%;
    font-size: 0.7rem;
    font-weight: 800;
    color: rgb(252, 122, 122);
}

.n-entry-slider-abs-norm {
    position: absolute;
    left: 0;
    width: 100%;
    height: 80%;
    border-radius: 0.8rem;
    background-color: #ECECEC;
}

.create-entry-slider::-webkit-slider-thumb {
    z-index: 9999;
}

.create-entry-slider {
    z-index: 9999;
}

.n-claim-circle {
    height: 1rem;
    width: 1rem;
    border-radius: 100%;
    background-color: #78C6A3;
    position: absolute;
    top: 0;
    right: 0;
    animation: n-claim-bounce 1s ease-in-out infinite;
}

@keyframes n-claim-bounce {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.n-create-entry-null {
    background-color: #92DDBC;
    color: #454b54;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.4rem;
    border: 1px solid #454b54;
    font-weight: 800;
    font-size: 0.7rem;
    font-family: "Inter";
    width: 66%;
    height: 2.3rem;
    margin-top: 1rem;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out; 
    filter: grayscale(0.6);
    animation: entry-sent 0.5s ease-in-out 1;
}

@keyframes entry-sent {
    0% {
        transform: scale(0.8);
    }
    80% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

.n-create-entry-null:hover {
    cursor: not-allowed;
}

.n-create-entry {
    background-color: #92DDBC;
    color: #454b54;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.4rem;
    border: 1px solid #454b54;
    font-weight: 800;
    font-size: 0.7rem;
    font-family: "Inter";
    width: 66%;
    height: 2.3rem;
    margin-top: 1rem;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.n-create-entry:hover {
    cursor: pointer;
    background-color: #80d3b7;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.n-create-entry:active {
    transform: scale(0.95);
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.n-mbs-l {
    height: 1px;
    width: 55%;
    margin-top: 0.4rem;
    background-color: #06AB78;
    display: flex;
    justify-content: left;
    align-items: center;
}

.ys-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #6F6F6F;
    font-size: 0.7rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.n-stickers-grid {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 20% 20% 20% 20%;
    row-gap: 0.8rem;
    column-gap: 0.8rem;
}

.ys-bar {
    display: flex;
    height: 1px;
    background-color: #6F6F6F;
    width: 35%;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
}

.n-mbs-box {
    display: flex;
    place-items: center;
    margin-top: 1.5rem;
}

/* HTML: <div class="loader"></div> */
.loader-entry {
    width: 50px;
    aspect-ratio: 1;
    --c:no-repeat radial-gradient(farthest-side,#514b82 92%,#0000);
    background: 
      var(--c) 50%  0, 
      var(--c) 50%  100%, 
      var(--c) 100% 50%, 
      var(--c) 0    50%;
    background-size: 10px 10px;
    animation: l18 1s infinite;
    position: relative;
  }
  .loader-entry::before {    
    content:"";
    position: absolute;
    inset:0;
    margin: 3px;
    background: repeating-conic-gradient(#0000 0 35deg,#514b82 0 90deg);
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 3px),#000 0);
    border-radius: 50%;
  }
  @keyframes l18 { 
    100%{transform: rotate(.5turn)}
  }

.n-book-sticker-box {
    border: 1px solid #A6A6A6;
    border-radius: 0.8rem;
    padding: 2rem 3rem 2rem 3rem;
    background-color: #FCFCFC;
    width: 25rem;
    box-shadow: 0px 4px 6px rgb(192, 192, 192);
}

.n-book-sticker-box2 {
    border: 1px solid #A6A6A6;
    border-radius: 0.8rem;
    padding: 2rem 3rem 2rem 3rem;
    background-color: #FCFCFC;
    width: 25rem;
    box-shadow: 0px 4px 6px rgb(192, 192, 192);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.n-lighter-btn-null {
    background-color: rgb(245, 245, 245);
    border: 1px solid #454b54;
    border-radius: .4rem;
    color: #78C6A3;
    font-family: Inter;
    font-weight: 600;
    font-size: 0.7rem;
    margin-top: 1.4rem;
    padding: .5rem 0;
    filter: grayscale(0.6);
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.n-lighter-btn-null:hover {
    cursor: not-allowed;
}

.n-lighter-btn:hover {
    cursor: pointer;
    background-color: #b7e2d5;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.n-lighter-btn:active {
    transform: scale(0.95);
    transition: transform 0.3s ease-in-out;
}

.n-dropdown-filter-item {
    padding: 0.7rem 0rem 0.7rem 0rem;
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    transition: background-color 0.3s ease-in-out;
}

.n-dropdown-filter-item:hover {
    cursor: pointer;
    background-color: rgb(236, 236, 236);
    transition: background-color 0.3s ease-in-out;
}

.n-library-options-nav {
    width: 90%;
    padding: 1rem 0rem 1rem 0rem;
    margin-top: 1rem;
    display: flex;
    align-items: center;
}

.option-library {
    color: #06AB78;
    font-weight: 700;
    font-size: 1rem;
    display: flex;
}

.option-showing {
    font-size: 0.7rem;
    color: #9D9D9D;
    margin-top: 0.2rem;
}

.n-filter-dropdown-box {
    position: absolute;
    top: 115%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    background-color: white;
    border: 1px solid rgb(198, 198, 198);
    box-shadow: 0px 4px 8px rgb(187, 187, 187);
    padding: 1rem 0rem 1rem 0rem;
    border-radius: 0.8rem;
    width: 100%;
}

.n-library-filter-box {
    position: absolute;
    right: 2rem;
    font-size: 0.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    width: 10rem;
    border-radius: 0.8rem;
    color: #5A5A5A;
    background-color: white;
    border: 1px solid #06AB78;
    transition: background-color 0.3s ease-in-out;
}

.n-library-filter-box:hover {
    cursor: pointer;
    background-color: #f3f3f3;
    transition: background-color 0.3s ease-in-out;
}

.goals-section-hide {
    height: 100%;
    width: 0%;
    border: 1px solid #C4C4C4;
    border-radius: 0.8rem;
    margin-right: 2%;
    padding-bottom: 1rem;
    animation: hide-goals 0.7s ease-in-out 1;
    background-color: white;
}

.n-streak-check {
    display: flex;
    flex-direction: column;
    place-items: center;
}

.n-streak-check-stats {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.n-streak-check-title {
    display: flex;
    place-items: center;
    margin: 0.5rem;
    font-size: 0.75rem;
    color: #9D9D9D;
    font-weight: 500;
}

.goals-section {
    width: 30%;
    border: 1px solid #C4C4C4;
    background-color: white;
    border-radius: 0.8rem;
    margin-right: 2%;
    height: 100%;
    padding-bottom: 2rem;
    animation: show-goals 0.7s ease-in-out 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.library-section-expand {
    height: 80%;
    width: 100%;
    background-color: white;
    border: 1px solid #C4C4C4;
    border-radius: 0.8rem;
    padding-bottom: 1rem;
    animation: library-expand 0.7s ease-in-out 1;
}

.library-section {
    height: 100%;
    width: 68%;
    background-color: white;
    border: 1px solid #C4C4C4;
    border-radius: 0.8rem;
    animation: show-library 0.7s ease-in-out 1;
    padding-bottom: 2rem;
}

.library-banner2 {
    height: 18%;
    width: 100%;
    background-color: #78C6A3;
    border-radius: 0.8rem;
    border: 1px solid #06AB78;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.library-banner {
    height: 4rem;
    width: 100%;
    background-color: #78C6A3;
    border-radius: 0.8rem;
    border: 1px solid #06AB78;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.adding-book-banner {
    position: absolute;
    top: 101%;
    width: 60%;
    height: 5.4rem;
    border-bottom-right-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
    border: 1px solid #06AB78;
    border-top: none;
    background-color: #a1dac0;
    animation: banner 0.5s ease-in-out 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pin-filled {
    animation: pin 0.5s ease-in-out 1;
}

@keyframes pin {
    0% {
        transform: scale(0.7);
    }
    85% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes banner {
    0% {
        height: 0rem;
    }
    100% {
        height: 5.4rem;
    }
}

.adding-book-banner-info {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    color: white;
    font-size: 1.4rem;
    font-weight: 600;
}

.abb-0 {
    font-size: 0.9rem;
    font-weight: 500;
}

.abb-1 {
    padding: 0.3rem 0.6rem 0.3rem 0.6rem;
    background-color: white;
    color:#78C6A3;
    font-weight: 600;
    border: 1px solid #06AB78;
    font-family: "Inter";
    font-size: 1.2rem;
    border-radius: 0.4rem;
    margin-right: 0.625rem;
    transition: background-color 0.3s ease-in-out;
    z-index: 111;
}

.abb-1:hover {
    cursor: pointer;
    background-color: rgb(245, 245, 245);
    transition: background-color 0.3s ease-in-out;
}

.library-input-container {
    margin-left: 3rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    position: relative;
}

.library-input {
    border: 1px solid #06AB78;
    border-radius: 0.6rem;
    height: 2.4rem;
    width: 100%;
    padding-left: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Inter";
    color: #454B54;
    font-weight: 500;
    font-size: 1rem;
}

.library-input:focus {
    outline: 2px solid #06AB78;
}

.library-input::placeholder {
    font-family: "Inter";
    color: #5A5A5A;
    font-size: 1rem;
    display: flex;
    align-items: center;
    height: 100%;
    font-weight: 400;
}

.library-sort-container {
    width: 55%;
    display: flex;
    justify-content: right;
    align-items: center;
    padding-right: 3rem;
}

.library-sort {
    background-color: white;
    border: 1px solid #06AB78;
    width: 11rem;
    border-radius: 1rem;
    height: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease-in-out;
    position: relative;
}

.library-sort:hover {
    cursor: pointer;
    background-color: #f5f5f5;
    transition: background-color 0.3s ease-in-out;
}

.library-sort-abs {
    position: absolute;
    top: 102%;
    right: 0;
    background-color: #FDFDFD;
    box-shadow: 0px 4px 5px rgb(216, 216, 216);
    padding: 0.4rem;
    padding-right: 0.4rem;
    width: 9rem;
    border-radius: 0.8rem;
    z-index: 121;
}

.library-sort-tab {
    padding: 0.4rem 0.3rem 0.4rem 0.3rem;
    border-radius: 0.4rem;
    display: flex;
    justify-content: left;
    align-items: center;
    transition: background-color 0.3s ease-in-out;
}

.library-sort-tab:hover {
    cursor: pointer;
    background-color: #f3f3f3;
    transition: background-color 0.3s ease-in-out;
}

.library-contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90%;
    width: 100%;
}

.library-contents-left {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 50%;
    color: #06AB78;
    font-weight: 500;
    font-size: 1.2rem;
}

.library-contents-right {
    display: flex;
    justify-content: right;
    align-items: center;
    width: 50%;
}

.library-plus {
    display: flex;
    margin-left: 0.3rem;
    padding: 0.2rem;
    padding-left: 0.3rem;
    border-radius: 0.4rem;
    transition: background-color 0.3s ease-in-out;
}

.library-plus:hover {
    cursor: pointer;
    background-color: #f5f5f5;
    transition: background-color 0.3s ease-in-out;
}

.library-holding-books {
    display: flex;
    justify-content: left;
    align-items: center;
    color: #9D9D9D;
    font-size: 0.8125rem;
    font-weight: 500;
    width: 90%;
}

.library-books-box {
    display: flex;
    flex-grow: 1;
    width: 95%;
    overflow-y: auto;
    padding-top: 2rem;
}

.hold-click {
    margin-bottom: 1rem;
    margin-left: 0.2rem;
    font-size: 0.9rem;
    font-weight: 500;
}

.library-bottom {
    width: 100%;
    display: flex;
    margin-top: 0.4rem;
}

.library-bottom-sec0 {
    width: 75%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.library-bottom-sec1 {
    width: 25%;
    display: flex;
    justify-content: right;
    align-items: center;
    font-size: 0.7rem;
}

.library-pages-bar {
    width: 100%;
    border-radius: 0.8rem;
    height: 0.7rem;
    background-color: rgb(238, 238, 238);
    position: relative;
}

.library-pages-bar-fill {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 0%;
    background-color: #78C6A3;
    border-radius: 0.8rem;
}

.library-hold-container {
    border: 1px solid #d2d4d8;
    background-color: #FDFDFD;
    padding: 0.7rem;
    padding-bottom: 1.8rem;
    border-radius: 0.8rem;
    padding-right: 2rem;
    padding-left: 1rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: fit-content;
    position: relative;
    transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out;
    animation: fav2 2s ease-in-out infinite;
}

@keyframes fav2 {
    0% {
        transform: scale(1);
    }
    20% {
        transform: scale(1.02);
    }
    80% {
        transform: scale(1.02);
    }
    100% {
        transform: scale(1.02);
    }
}

.library-hold-container {
    cursor: pointer;
    background-color: #fafafa;
    border: 1px solid #c8cace;
    transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out;
}

.library-book-completed { 
    border: 1px solid #d2d4d8;
    background-color: #f1faf6;
    padding: 0.7rem;
    padding-bottom: 1.8rem;
    border-radius: 0.8rem;
    padding-right: 2rem;
    padding-left: 1rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    position: relative;
    transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out;
}

.library-book-completed:hover {
    cursor: pointer;
    background-color: #e6f5eb;
    border: 1px solid #c8cace;
    transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out;
}

.library-book-container-held {
    border: 1px solid #cc382e;
    background-color: white;
    padding: 1.2rem;
    padding-bottom: 2rem;
    border-radius: 0.8rem;
    padding-right: 2rem;
    padding-left: 1rem;
    box-sizing: border-box;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    position: relative;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    z-index: 10;
    transform: scale(0.8125);
    height: 155.84px;
    max-height: 155.84px;
    cursor: pointer;
}

.library-book-container-delete {
    border: 1px solid #7d8694;
    background-color: white;
    padding: 1.2rem;
    padding-bottom: 2rem;
    border-radius: 0.8rem;
    padding-right: 2rem;
    padding-left: 1rem;
    box-sizing: border-box;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    position: relative;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    z-index: 10;
    transform: scale(0.8125);
    height: 155.84px;
    max-height: 155.84px;
    cursor: pointer;
}

.library-book-container-claim{ 
    background-color: white;
}

.library-book-container-claim {
    border: 1px solid #7d8694;
    padding: 1.2rem;
    padding-bottom: 2rem;
    border-radius: 0.8rem;
    padding-right: 2rem;
    padding-left: 1rem;
    box-sizing: border-box;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    position: relative;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    z-index: 29;
    transform: translateZ(0) scale(0.8125);
    height: 155.84px;
    max-height: 155.84px;
    width: 26rem;
    will-change: transform;
    backface-visibility: hidden;
    transform-style: flat;
    filter: opacity(0.3);
}

.library-book-container-claim:hover {
    cursor: pointer;
    background-color: rgb(233, 233, 233);
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.library-book-null {
   border: 1px solid #7d8694;
    background-color: white;
    padding: 1.2rem;
    padding-bottom: 2rem;
    border-radius: 0.8rem;
    padding-right: 2rem;
    padding-left: 1rem;
    box-sizing: border-box;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    position: relative;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    z-index: 10;
    transform: translateZ(0) scale(0.8125);
    height: 155.84px;
    max-height: 155.84px;
    width: 26rem;
    will-change: transform;
    backface-visibility: hidden;
    transform-style: flat;
    filter: grayscale(0.7) opacity(0.6);
    cursor: not-allowed;
}

.custom-library-cont {
    border: 1px solid #7d8694;
    background-color: white;
    padding: 1.2rem;
    padding-bottom: 2rem;
    border-radius: 0.8rem;
    padding-right: 2rem;
    padding-left: 1rem;
    box-sizing: border-box;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    transition: transform 0.5s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    z-index: 998;
    transform: scale(0.8125) rotateY(180deg);
    height: 155.84px;
    max-height: 155.84px;
    will-change: transform;
    backface-visibility: hidden;
}

.my-cont-book {
    border: 1px solid #7d8694;
    background-color: white;
    padding: 1.2rem;
    padding-bottom: 2rem;
    border-radius: 0.8rem;
    padding-right: 2rem;
    padding-left: 1rem;
    box-sizing: border-box;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    position: relative;
    transition: transform 0.6s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    z-index: 10;
    transform: translateZ(0) scale(0.8125) rotateY(180deg);
    height: 155.84px;
    max-height: 155.84px;
    width: 26rem;
    will-change: transform;
    backface-visibility: hidden;
    transform-style: preserve-3d;
}

.b-flip-card-front, .b-flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    left: 0;
    bottom: 0;
    border-radius: 0.8rem;
  }

  .b-flip-card-back {
    background-color: white;
    color: white;
    transform: rotateY(180deg);
    border: 1px solid #7d8694;
    will-change: transform;
  }

.library-book-load-anim {
    border: 1px solid #7d8694;
    background-color: rgb(243, 243, 243);
    padding: 1.2rem;
    padding-bottom: 2rem;
    border-radius: 0.8rem;
    padding-right: 2rem;
    padding-left: 1rem;
    box-sizing: border-box;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    position: relative;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    z-index: 10;
    transform: scale(0.8125);
    height: 155.84px;
    max-height: 155.84px;
    will-change: transform;
    backface-visibility: hidden;
    filter: grayscale(0.7) opacity(0.1);
    cursor: not-allowed;
    animation: do-something 0.5s ease-in-out 1;
}

@keyframes do-something {
    0% {
        transform: scale(0.7);
    }
    80% {
        transform: scale(0.88);
    }
    100% {
        transform: scale(0.8125);
    }
}

.library-book-container {
    border: 1px solid #7d8694;
    background-color: white;
    padding: 1.2rem;
    padding-bottom: 2rem;
    border-radius: 0.8rem;
    padding-right: 2rem;
    padding-left: 1rem;
    box-sizing: border-box;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    position: relative;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    z-index: 28;
    transform: translateZ(0) scale(0.8125);
    height: 155.84px;
    max-height: 155.84px;
    width: 26rem;
    will-change: transform;
    backface-visibility: hidden;
    transform-style: flat;
}

.library-book-container:hover {
    cursor: pointer;
    background-color: #f7f7f7;
    border: 1px solid #6c7585;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    box-shadow: 0px 4px 8px rgb(204, 204, 204);
}

.library-book-container:active {
    transform: scale(0.8);
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

@keyframes book-container-anim {
    0% {
        transform: scale(0.7);
    }
    80% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(0.8125);
    }
}

.library-book-circle-gray {
    height: 6rem;
    width: 6rem;
    border-radius: 100%;
    background-color: #DBDBDB;
    border: 1px solid #a8a8a8;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.library-book-circle {
    height: 6rem;
    width: 6rem;
    border-radius: 100%;
    background-color: #7eceac;
    border: 1px solid rgb(120, 175, 163);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.library-cover {
    height: 6.5rem;
    width: 4rem;
    border-radius: 0.2rem;
    border: 1px solid #878f9b;
}

.sticker0y-abs {
    position: absolute;
    height: 2.6rem;
    display: flex;
    bottom: 95%;
    z-index: 0;
}

.sticker0z-abs {
    position: absolute;
    height: 2.6rem;
    display: flex;
    bottom: 90%; 
}

.sticker0-abs {
    position: absolute;
    height: 2.6rem;
    display: flex;
    bottom: 100%;
}

.sticker1y-abs {
    position: absolute;
    height: 2.6rem;
    display: flex;
    top: 68%;
    left: 63%;
    z-index: 404; 
}

.sticker1z-abs {
    position: absolute;
    height: 2.6rem;
    display: flex;
    top: 65%;
    left: 60%;
    z-index: 404;
}

.sticker1-abs {
    position: absolute;
    height: 2.6rem;
    display: flex;
    top: 70%;
    left: 70%;
    z-index: 404;
}

.sticker2-abs {
    position: absolute;
    height: 3.6rem;
    display: flex;
    top: 46%;
    left: 100%;
}

.library-book-arrow {
    position: absolute;
    top: 2%;
    left: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.library-vert-bar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    margin-left: 1.2rem;
    height: 100%;
}

.library-vert-bar {
    border-radius: 0.8rem;
    width: 0.5rem;
    height: 5.4rem;
    background-color: #D9D9D9;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: end;
}

.library-vert-bar-fill {
    position: absolute;
    width: 100%;
    height: 0%;
    border-radius: 0.8rem;
    background-color: #27AE85;
}

.heart-empty {
    animation: opac 0.3s ease-in-out 1;
}

.library-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
    padding-bottom: 1rem;
    z-index: 100;
}

.library-book-info {
    margin-left: 2rem;
    margin-right: 0.625rem;
    width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    font-size: 0.8125rem;
    margin-bottom: -0.8rem;
    width: 11rem;
    position: relative;
}

.library-book-title {
    font-size: 1.2em;
    font-weight: 700;
    width: 11rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;  /* Limit the text to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 3em;  /* 2 lines * line-height */
}

.library-grid-expand {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto auto;
    row-gap: 2rem;
    column-gap: 2rem;
    justify-content: center;
    width: 100%;
    height: fit-content;
    padding-top: 0.4rem;
    margin-left: 2.5rem;
}

.library-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    row-gap: 2rem;
    column-gap: 2rem;
    justify-content: center;
    width: 100%;
    height: fit-content;
    margin-left: 2.5rem;
    grid-template-columns: 26rem 26rem;
}

.library-book-author {
    font-size: 0.9em;
    font-weight: 500;
    color: #5A5A5A;
    margin-top: 0.1rem;
}

.library-book-genre-tag {
    padding: 0.25rem 0.6rem 0.25rem 0.6rem;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20rem;
    background-color: #FFF3F3;
    border: 1px solid #FFA9A9;
    font-size: 0.8125em;
    color: #FFA9A9;
    margin-top: 0.4rem;
}

.library-book-genre-circle {
    height: 1em;
    width: 1em;
    background-color: #FFA9A9;
    border-radius: 100%;
    margin-right: 0.4rem;
}

.library-favorite {
    position: absolute;
    right: 0.8rem;
    top: 0.8rem;
    border: 2px solid #f5bcda;
    height: 1.8rem;
    width: 1.8rem;
    background-color: white;
    border-radius: 0.6125rem;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; 
}

.library-check {
    position: absolute;
    right: 0.8rem;
    top: 0.8rem;
    border: 2px solid #d1d3d6;
    height: 1.8rem;
    width: 1.8rem;
    background-color: white;
    border-radius: 0.6125rem;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.library-check:hover {
    cursor: pointer;
    background-color: #f3f3f3;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.library-add-load {
    position: absolute;
    right: 0.8rem;
    top: 0.8rem;
    border: 1px solid #78C6A3;
    height: 1.8rem;
    width: 1.8rem;
    background-color: rgb(242, 248, 246);
    border-radius: 0.6125rem;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

.library-add {
    position: absolute;
    right: 0.8rem;
    top: 0.8rem;
    border: 1px solid #78C6A3;
    height: 1.8rem;
    width: 1.8rem;
    background-color: white;
    border-radius: 0.6125rem;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

.library-add:hover {
    cursor: pointer;
    background-color: #e8f3ee;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.library-add:active {
    transform: scale(0.9);
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.library-check:active {
    transform: scale(0.9);
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.show-check {
    animation: opac 0.3s ease-in-out 1;
}

.library-pages {
    position: absolute;
    bottom: 0.4rem;
    right: 0.8rem;
    font-size: 0.7em;
    font-weight: 500;
    color: #5A5A5A;
    display: flex;
    justify-content: center;
    align-items: center;
}

.library-pin {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3rem;
    padding-right: 0.2rem;
    border-radius: 0.4rem;
    transition: background-color 0.3s ease-in-out;
}

.library-pin:hover {
    cursor: pointer;
    background-color: #e7e8eb;
    transition: background-color 0.3s ease-in-out;
}

.n-add-to-library {
    color: #27AE85;
    font-weight: 600;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 1rem;
    transition: background-color 0.3s ease-in-out;
    border-radius: 0.4rem;
    width: fit-content;
}

.n-adding-info-box {
    width: 75%;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: white;
    height: 10rem;
    border: 1px solid #B8B8B8;
    border-radius: 0.8rem;
    margin-top: 2rem;
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
}

.n-library-book-temp-info-grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    font-size: 1rem;
    margin-left: 2rem;
    width: 24rem;
}

.n-temp-0 {
    font-weight: 600;
    font-size: 1em;
}

.n-temp-1 {
    font-weight: 500;
    font-size: 0.8125em;
    color: #8D8D8D;
    margin-top: 0.4rem;
}

.n-temp-2 {
    border: 1px solid #78C6A3;
    color: #78C6A3;
    background-color: white;
    border-radius: 0.4rem;
    padding: 0.45rem 1.4rem 0.45rem 1.4rem;
    width: fit-content;
    font-weight: 600;
    margin-top: 1.5rem;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, border 0.3s ease-in-out;
}

.n-temp-2:hover {
    cursor: pointer;
    background-color: #78C6A3;
    color: white;
    border: 1px solid #06AB78;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, border 0.3s ease-in-out;
}

.loader {
    border: 6px solid #f3f3f3;
    border-radius: 50%;
    border-top: 6px solid #78C6A3;
    width: 0.4rem;
    height: 0.4rem;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
}

.loader-heart {
    border: 6px solid #f3f3f3;
    border-radius: 50%;
    border-top: 6px solid #f5bcda;
    width: 0.4rem;
    height: 0.4rem;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.heart-beat {
    animation: heart-beat 1.2s ease-in-out 1;
    transition: transform 0.3s ease-in-out;
    transform-origin: center center;
}

.heart-beat:hover {
    transform-origin: center center;
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
}

@keyframes heart-beat {
    0% {
        transform-origin: center center;
        transform: translateY(1.5rem);
    }
    40% {
        transform-origin: center center;
        transform: translateY(0rem);
    }
    60% {
        transform-origin: center center;
        transform: scale(1.1);
    }
    70% {
        transform-origin: center center;
        transform: scale(0.9);
    }
    85% {
        transform-origin: center center;
        transform: scale(1.1);
    }
    100% {
        transform-origin: center center;
        transform: scale(1);
    }
}

.library-page-abs {
    position: absolute;
    right: 2rem;
    bottom: 0.8rem;
    font-size: 0.7rem;
    font-weight: 500;
    color: #5A5A5A;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.heart-icon {
    position: absolute;
    height: 2rem;
    right: -1rem;
    top: 0.625rem;
    transform: rotate(20deg);
    animation: heart-icon 4s ease-in-out infinite;
    z-index: 3000;
}

@keyframes heart-icon {
    0% {
        transform: rotate(20deg);
    }
    20% {
        transform: rotate(30deg);
    }
    60% {
        transform: rotate(10deg);
    }
    80% {
        transform: rotate(20deg);
    }
    100% {
        transform: rotate(20deg);
    }
}

.favorite-progress {
    height: 100%;
    border-radius: 0.8rem;
    width: 100%;
    background-color: #FFEAF4;
    position: absolute;
    left: 0;
    bottom: 0;
    animation: fav 2s ease-in-out 1;
}

@keyframes fav {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}

.add-book-lib {
    padding: 0.6rem;
    padding-left: 0.7rem;
    background-color: #c9e9db;
    border-radius: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    border: 1px solid #06AB78;
    transform-origin: center center;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.add-book-lib:hover {
    cursor: pointer;
    background-color: #b1ddce;
    transform-origin: center center;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.library-reading-goals {
    color: white;
    font-size: 1.625rem;
    font-weight: 500;
    width: 100%;
    display: flex;
    justify-content: left;
    margin-left: 10%;
}

.goals-section-container {
    width: 100%;
    height: 65%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    position: relative;
    overflow-y: auto;
    padding-bottom: 2rem;
}

.reading-goal-table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 100%;
    margin-top: 2rem;
}

.reading-goal-table-title {
    font-size: 1em;
    font-weight: 600;
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    color: #797979;
}

.time-remaining-goals {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0.7rem 0.25rem 0.7rem;
    height: fit-content;
    color: #454B54;
    font-size: 0.8125em;
    border-radius: 0.8rem;
    background-color: #F7F7F7;
    margin-left: 0.4rem;
}

.reading-goal-quest {
    border: 1px solid #DFE1E5;
    padding: 0.625rem 1rem 0.625rem 1rem;
    border-radius: 0.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: fit-content;
    transition: background-color 0.3s ease-in-out;
}

.reading-goal-quest:hover {
    background-color: rgb(252, 252, 252);
    transition: background-color 0.3s ease-in-out;
}

.green-circle {
    height: 4.4rem;
    width: 4.4rem;
    border-radius: 100%;
    background-color: #ebebeb;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rgq-0 {
    position: absolute;
    height: 3.4rem;
    top: 15%;
}

.reading-goal-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    margin-left: 2rem;
    font-size: 1rem;
    color: #06AB78;
    font-weight: 600;
    width: 14rem;
}

.rgi-0 {
    font-size: 0.8125em;
    font-weight: 600;
    display: flex;
    color: #454B54;
}

.rgq-bar {
    width: 85%;
    height: 0.7rem;
    border-radius: 0.4rem;
    background-color: #F1F1F1;
}

.rgq-num {
    font-size: 0.7em;
    margin-left: 0.4rem;
}

.slide-buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 0.7rem;
}

.recent-entries-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    margin-top: 2rem;
    position: relative;
}

.goal-sec-flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    border-radius: 0.8rem;
}

.goal-sec-grid0 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.goal-sec-circle {
    height: 1.625rem;
    width: 1.625rem;
    border-radius: 100%;
    background-color: #A6D8C2;
}

.goal-sec-item-claim {
    border: 1px solid #06AB78;
    padding: 0.7rem 0.9rem 0.7rem 0.9rem;
    border-radius: 0.4rem;
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
    transition: background-color 0.3s ease-in-out;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    background-color: white;
    width: 22rem;
    cursor: pointer;
}

.goal-sec-item-claim:hover {
    background-color: rgb(252, 252, 252);
    transition: background-color 0.3s ease-in-out;
}

.goal-sec-item-container {
    border: 1px solid #8d8d8d;
    padding: 0.7rem 0.9rem 0.7rem 0.9rem;
    border-radius: 0.4rem;
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
    transition: background-color 0.3s ease-in-out;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    background-color: white;
    width: 22rem;
    cursor: pointer;
}

.goal-sec-item-container:hover {
    background-color: rgb(252, 252, 252);
    transition: background-color 0.3s ease-in-out;
}

.goal-sec-item-circle-null {
    position: relative;
    border-radius: 100%;
    background-color: #ebebeb;
    height: 4rem;
    width: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.goal-sec-item-circle {
    position: relative;
    border-radius: 100%;
    background-color: #78C6A3;
    height: 4rem;
    width: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.goal-sec-item-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    font-size: 0.9rem;
    font-weight: 600;
    width: 70%;
    margin-left: 2rem;
}

.goal-sec-item-tag {
    padding: 0.2rem 0.4rem 0.2rem 0.4rem;
    background-color: #F5F5F5;
    border: 1px solid #E8E8E8;
    border-radius: 0.8rem;
    width: fit-content;
    margin-top: 0.4rem;
    font-size: 0.8125em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.goal-section-file-null {
    position: absolute;
    height: 3.625rem;
}

.goal-section-file {
    position: absolute;
    height: 3.625rem;
    animation: goal-sec-file 2s ease-in-out infinite;
}

@keyframes goal-sec-file {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(10deg);
    }
    75% {
        transform: rotate(-10deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.goal-sec-date {
    position: absolute;
    right: 0.625rem;
    bottom: 0.4rem;
    font-size: 0.7em;
    color: #7E7E7E;
}

.goal-sec-sep {
    height: 1px;
    width: 100%;
    border-radius: 0.4rem;
    background-color: #a9adb3;
    margin: 2rem 0 2rem 0;
}

.goal-arrow {
    padding: 0.15rem;
    margin: 0.1rem;
    border-radius: 0.4rem;
    display: flex;
    transition: background-color 0.3s ease-in-out;
}

.goal-arrow:hover {
    cursor: pointer;
    background-color: rgb(241, 241, 241);
    transition: background-color 0.3s ease-in-out;
}

.streak-sec-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 80%;
    padding: 1rem 0.9rem 1rem 0.9rem;
    border-radius: 0.4rem;
}

.streak-fire-circle {
    height: 4.8rem;
    width: 4.8rem;
    border-radius: 2rem;
    background-color: white;
    position: relative;
}

.fire-icon {
    position: absolute;
    bottom: 5%;
    left: -10%;
    height: 5rem;
    cursor: pointer;
}

.streak-fire-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    margin-left: 1.625rem;
    font-size: 1rem;
    width: 16rem;
}

.rs-0 {
    color: white;
    font-weight: 600;
    font-size: 1.1em;
}

.rs-1 {
    color: white;
    font-weight: 600;
    font-size: 1.1em; 
}

.rs-2 {
    color: white;
    font-weight: 400;
    font-size: 0.7em;
    margin-top: 0.3rem;
}

.prog-0 {
    color: white;
    font-size: 0.7em;
    font-weight: 500;
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.prog-bar {
    width: 60%;
    height: 0.5rem;
    border-radius: 0.8rem;
    background-color: white;
}

.streak-sec-box {
    width: 100%;
    background-color: #78C6A3;
    border-radius: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0.625rem;
    padding-top: 0.625rem;
    border: 1px solid #06AB78;
    margin-top: 1.5rem;
    height: 22%;
    position: absolute;
    bottom: 0;
}

.goal-table-main {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    width: fit-content;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.light-fire {
    background-color: white;
    font-size: 1.2rem;
    border: 1px solid #454B54;
    color:#06AB78;
    font-family: 'Inter';
    font-weight: 600;
    padding: 0.4rem 0.6rem 0.4rem 0.6rem;
    border-radius: 0.4rem;
    width: 90%;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out, color 0.3s ease-in-out;
    z-index: 10;
}

.light-fire:hover {
    cursor: pointer;
    color: orange;
    background-color: rgb(247, 241, 232);
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out, color 0.3s ease-in-out;
}

.light-fire:active {
    transform: scale(0.98);
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out, color 0.3s ease-in-out;
}

.light-fire-null {
    background-color: rgb(248, 236, 222);
    font-size: 1.2rem;
    border: 1px solid orange;
    color: rgb(253, 199, 99);
    font-family: 'Inter';
    font-weight: 600;
    padding: 0.4rem 0.6rem 0.4rem 0.6rem;
    border-radius: 0.4rem;
    width: 90%;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out, color 0.3s ease-in-out;
    z-index: 10;
    filter: grayscale(0.6);
    cursor: not-allowed;
}

.mini-file0 {
    position: absolute;
    height: 1.4rem;
    opacity: 0;
    z-index: 9;
    animation: mf-0 0.6s ease-in-out 1;
}

.mini-fire0, .mini-fire1, .mini-fire2 {
    position: absolute;
    height: 1.4rem;
    opacity: 0;
    z-index: 9;
}

.streak-btnxD {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
}

.minixd0, .minixd1, .minixd2 {
    position: absolute;
    height: 1.4rem;
    opacity: 0;
    z-index: 9;
    bottom: -100%;
    left: 140%;
}

.minixd0 {
    animation: mf-0 0.2s ease-in-out 1;
    left: 15%;
    opacity: 0;
}

.mini-fire0 {
    animation: mf-0 0.2s ease-in-out 1;
    left: 15%;
    opacity: 0;
}

@keyframes mf-0 {
    0% {
        transform: translateY(0%);
        opacity: 1
    }
    100% {
        transform: translateY(-200%);
        opacity: 0;
    }
}

.minixd1 {
    animation: mf-1 0.4s ease-in-out 1; 
    right: 15%;
    opacity: 0;
}

.mini-fire1 {
    animation: mf-1 0.4s ease-in-out 1; 
    right: 15%;
    opacity: 0;
}

@keyframes mf-1 {
    0% {
        transform: translateY(0%);
        opacity: 1;
    }
    30% {
        transform: translateY(0%);
        opacity: 1;
    }
    100% {
        transform: translateY(-200%);
        opacity: 1;
    }
}

.minixd2 {
    animation: mf-2 0.6s ease-in-out 1;
    left: 40%;
    opacity: 0;  
}

.mini-fire2 {
    animation: mf-2 0.6s ease-in-out 1;
    left: 40%;
    opacity: 0;
}

.streak-box-omega {
    display: flex;
}

@keyframes mf-2 {
    0% {
        transform: translateY(0%);
        opacity: 1
    }
    50% {
        transform: translateY(0%);
        opacity: 1;
    }
    100% {
        transform: translateY(-200%);
        opacity: 0;
    }
}

.circle-click {
    position: absolute;
    top: -0.3rem;
    right: -0.3rem;
    height: 1rem;
    width: 1rem;
    border-radius: 100%;
    background-color: #78C6A3;
}

.abb-xd {
    font-size: 1.4rem;
}

.whatever-div {
    display: flex;
    justify-content: center;
    width: 100%;
}

/* Media Queries */

@media screen and (min-width: 320px) and (max-width: 569px){
    
}

@media screen and (min-width: 570px) and (max-width: 999px){
    
}

@media screen and (min-width: 1000px) and (max-width: 1249px){
    
    
}

@media only screen and (min-width: 800px) and (max-width: 1149px) {
    .whatever-div {
        width: 80%;
    }
    .library-book-container {
        transform: scale(0.8);
    }
    .n-reading-entry-flex {
        transform: scale(0.8);
    }
    .filter-item-active {
        font-size: 0.6rem;
    }
    .filter-item {
        font-size: 0.6rem;
    }
    .filter-list {
        margin-left: 2rem;
    }
    .filter-search {
        margin-left: -1rem;
    }
    .n-lighter-info {
        font-size: 0.6rem;
        width: 12rem;
    }
    .n-lighter-title {
        font-size: 0.8125rem;
    }
    .n-streak-circle {
        height: 4rem;
        width: 4rem;
    }
    .n-lighter-img {
        height: 4rem;
    }
    .n-new-nav {
        width: 68rem;
    }
}

@media only screen and (min-width: 1150px) and (max-width: 1650px) {
    .whatever-div {
        width: 80%;
    }
    .library-book-container {
        transform: scale(0.8);
    }
    .n-reading-entry-flex {
        transform: scale(0.8);
    }
    .filter-item-active {
        font-size: 0.6rem;
    }
    .filter-item {
        font-size: 0.6rem;
    }
    .filter-list {
        margin-left: 2rem;
    }
    .filter-search {
        margin-left: -1rem;
    }
    .n-lighter-info {
        font-size: 0.6rem;
        width: 12rem;
    }
    .n-lighter-title {
        font-size: 0.8125rem;
    }
    .n-streak-circle {
        height: 4rem;
        width: 4rem;
    }
    .n-lighter-img {
        height: 4rem;
    }
    .n-new-nav {
        width: 68rem;
    }
}

.n-load-anim-base {
    position: absolute;
    height: 1.2rem;
    width: 60%;
    border-radius: 0.8rem;
    background-color: rgb(221, 221, 221);
    z-index: 989;
    display: flex;
    justify-content: left;
    align-items: center;
    animation: lol-pop 1.5s ease-in-out 1;
}

@keyframes lol-pop {
    0% {
        opacity: 0;
    }
    40% {
        transform: scale(0.7);
        opacity: 1;
    }
    80% {
        transform: scale(1.05);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.n-load-anim-fill {
    height: 1.2rem;
    width: 100%;
    border-radius: 0.8rem;
    background-color: #27AE85;
    border: 1px solid #368B65;
    animation: big-width 2s ease-in-out 1;
}

@keyframes big-width {
    0% {
        width: 0%;
        transform: scale(0.7);
    }
    70% {
        width: 50%;
        transform: scale(1.1);
    }
    100% {
        width: 100%;
        transform: scale(1);
    }
}