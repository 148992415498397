.bb-nav-container {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
}

.bb-nav-0 {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 60%;
}

.bb-nav-1 {
    display: flex;
    justify-content: right;
    align-items: center;
    width: 40%;
}

.bb-nav-active {
    padding: 0.4rem 1rem 0.4rem 1rem;
    background-color: #9cd6bc;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    border: 1px solid #06AB78;
    border-radius: 0.8rem;
    margin-right: 0.625rem;
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
}

.bb-nav-inactive {
    padding: 0.4rem 1rem 0.4rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    border: 1px solid #D9D9D9;
    border-radius: 0.8rem;
    margin-right: 0.625rem;
    transition: background-color 0.3s ease-in-out;
}

.bb-nav-inactive:hover {
    cursor: pointer;
    background-color: #E7E7E7;
    transition: background-color 0.3s ease-in-out;
}

.bb-nav-img {
    height: 1.625rem;
    margin-right: 0.7rem;
}

.bb-nav-img-active {
    height: 1.625rem;
    margin-right: 0.7rem;
    animation: bb-nav 6s ease-in-out infinite;
}

@keyframes bb-nav {
    0% {
        transform: rotate(0);
    }
    5% {
        transform: rotate(15deg);
    }
    15% {
        transform: rotate(-15deg);
    }
    20% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.bb-navbar-logo-icon {
    height: 2.4rem;
    padding: 0rem 0.525rem 0rem 0.525rem;
}